<template>
  <div style="padding: 20px;">
    <el-button type="primary" size="mini" @click="addAgent">添加品牌</el-button>
  </div>


  <lida-table :cutHeight="200" :pagination="false" :data="treeList" border ref="table">
    <lida-table-column label="申请时间" prop="auditTime" />
    <lida-table-column label="品牌名称" prop="brandName" width="200" />


    <lida-table-column label="代理证书" prop="certificateFiles">
      <template #default="scope">
        <div>
          <el-image v-if="getPICOfCertificateFiles(scope.row).length"
            style="width: 50px; height: 50px;object-fit: cover;" :src="getPICOfCertificateFiles(scope.row) &&
              getPICOfCertificateFiles(scope.row)[0]
              " :preview-src-list="getPICOfCertificateFiles(scope.row)"></el-image>
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="代理开始时间" prop="termStart" />
    <lida-table-column label="代理结束时间" prop="termEnd" />
    <lida-table-column label="审核状态" prop="auditState">
      <template #default="scope">
        <div class="a-r-title">
          <div>{{ ['待审核', '审核通过', '已过期', '审核不通过'][scope.row.auditState] }}</div>
          <el-button v-if="scope.row.auditState == 1" type="primary" size="mini" @click="addtip">添加系列</el-button>
        </div>
      </template>
    </lida-table-column>

    <lida-table-column label="" width="950" prop="quoteSetVOList">
      <template #default="scope">
        <div style="display:flex;flex-flow: wrap; " v-for="(v, i) in scope.row.quoteSetVOList" :key="i">
          <div class="flex" style="marginRight:30px">
            <div style="marginRight:10px">
              {{ v.belongName }}
            </div>
            <div style="marginRight:10px">自动接单折扣</div>
            <div class="a-i-discount">
              <el-input label="" style="width: 100px;" v-model="v.discount" placeholder="请输入折扣价" :disabled="v.disabled"
                @change="zkBlur(v, i)" />
            </div>
          </div>
          <div class="flex" style="marginRight:30px">
            <div style="marginRight:10px">货期</div>
            <div class="a-i-discount">
              <el-input style="width: 100px;" v-model="v.channel" placeholder="请输入货期" :disabled="v.disabled"
                @change="zkBlur(v, i)" />
            </div>
          </div>

          <div class="flex" style="marginRight:30px">
            <div style="marginRight:10px">发货地</div>
            <div class="a-i-discount">
              <el-input style="width: 200px;" v-model="v.shipArea" placeholder="请输入发货地" :disabled="v.disabled"
                v-if="v.disabled == true" />
              <el-cascader v-model="v.shipArea" :options="areaInfo" size="small" v-if="v.disabled == false"
                :props="{ expandTrigger: 'hover' }" style="width: 200px;" @change="zkBlur(v, i)"></el-cascader>
            </div>
          </div>
          <div class="flex">
            <span style="marginRight:20px" class="iconfont icon-bianjisekuai" @click="editZk(v, i)"
              v-if="v.disabled"></span>
            <span style="marginRight:20px" class="el-icon-delete" @click="del(v, i)" v-if="v.disabled"></span>
            <el-button type="text" @click="confirm(v, i)" v-if="v.disabled == false">确定修改</el-button>
          </div>
        </div>
      </template>
    </lida-table-column>
    <lida-table-column label="操作" fixed="right">
      <template #default="scope">
        <el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
      </template>
    </lida-table-column>
  </lida-table>


  <add-agent-dialog v-model="show" @success="addSuccess" />
  <addtip v-model="addshow" @success="addSuccess" />
</template>

<script>
import { mapActions } from "vuex";
import {
  handleTreeData2, //处理树形数据
  throttle, //节流
  handleTreeData,
} from "@/common/js/common.js";
import { ElMessageBox, ElMessage } from 'element-plus'
import area from "@/common/json/area.json";
import addtip from "../dialog/addtip.vue"; //新增系列弹框
import addAgentDialog from "../dialog/addAgent.vue"; //新增品牌弹框
import { type } from "os";
export default {
  data() {
    return {
      treeList: [], //树形列表数据
      activeIds: [], //当前选中的节点ids
      show: false, //新增弹框的隐藏显示
      addshow: false,
      list: [], //列表数据
      page: 1, //当前页数
      pageSize: 50, //每页显示的数量
      areaInfo: handleTreeData(area, "name", "name"), //地区信息
      // certificateFiles: [],
      // newFile: [], //保存证书列表
    };
  },
  components: {
    addAgentDialog,
    addtip,
  },

  computed: {},
  methods: {
    ...mapActions("mysaleMudule", [
      "listTreeReq", //获取左边tree数据
      "quoteSetList", //查找列表数据
      "quoteSetUpdate", //修改非代理设置
      "quoteAgentList",
      "quoteSetListBelongIds",
      "quoteAgentDelete",
      "quoteAgentDeleteAll"
    ]),
    getPICOfCertificateFiles(v) {
      return (
        (Array.isArray(v.certificateFiles) &&
          v?.certificateFiles
            .filter((ex) => ex.fileType === "PICTURE")
            .map((ex) => ex.url)) ||
        []
      );
    },
    getPDFOfCertificateFiles(v) {
      return (
        (Array.isArray(v.certificateFiles) &&
          v?.certificateFiles.filter((ex) => ex.fileType === "PDF")) ||
        []
      );
    },
    getTreeList() {
      //获取树形结构列表
      this.quoteAgentList({ type: "AGENT" }).then((res) => {
        // console.log(res);
        let { data, code } = res.data;
        if (code === "0") {
          // this.treeList = handleTreeData2(data, "brandName");
          // console.log(this.treeList);
          if (Array.isArray(data)) {
            this.treeList = data.map((ex) => {
              const t = { ...ex };
              if (ex.quoteSetVOList) {
                ex.quoteSetVOList.map((item) => {
                  // console.log(555,item.disabled);
                  item.disabled = true; //默认禁止修改
                });
              }
              if (ex.certificateFiles && ex.certificateFiles !== "[]") {
                t.certificateFiles = JSON.parse(ex.certificateFiles);
              }
              return t;
            });
          }

        }
      });
    },
    deleteRow(row) {
      ElMessageBox.confirm(
        "确认删除此品牌?",
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      ).then(({ value }) => {
        console.log(row);
        this.quoteAgentDeleteAll(row.row.id).then(res => {
          let { code, data } = res.data;
          if (code === '0') {
            ElMessage({
              type: 'success',
              message: '操作成功',
            })
            this.getList(); //刷新列表
            this.getTreeList();
          }
        })
      }).catch(() => { })
    },
    handleCheckChange(row, type) {
      const t = type?.checkedKeys?.slice().filter((ex) => ex) || [];

      if (t.length) {
        this.activeIds = t; //绑定当前选中的
        this.getList(); //请求列表数据
      }
    },

    getSonIds(data, arr) {
      //获取当前所有的子集
      arr = arr || [];
      // arr=''
      data.map((item) => {
        arr.push(item);
        // arr = item;
        // console.log(arr);
        arr = [
          ...arr,
          ...(item.children && item.children.length
            ? this.getSonIds(item.children)
            : []),
        ];
      });
      return arr;
    },
    getList() {
      //请求右边列表数据
      // console.log(this.activeIds);
      this.quoteSetListBelongIds({
        type: "AGENT",
        belongIds: this.activeIds,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          let arr = [];
          data.map((item) => {
            // console.log(111,item.quoteSetVOList);
            // arr.push(item.quoteSetVOList);
            // arr=[...item.quoteSetVOList]
            // console.log(2,arr);
            arr.push(item);
            // item.disabled = true; //默认禁止修改
            // item.quoteSetVOList[0].disabled = true; //默认禁止修改
            //  console.log(item.disabled);
          });
          arr.map((v) => {
            // console.log(44, v);
            v.quoteSetVOList.map((item) => {
              // console.log(555,item.disabled);
              item.disabled = true; //默认禁止修改
            });
            // v.disabled = true
          });
          this.list = [...arr];
          // console.log(333, this.list);
          // this.activeIds = [];
          //   console.log(data);
        }
      });
    },
    zkBlur(row, index) {
      // console.log(row,index);
      // 失去焦点
      if (row.shipArea instanceof Array) {
        row.shipArea = row.shipArea.join(",");
      }
      //  console.log(row.shipArea);
      // row.disabled = true;
      // this.quoteSetUpdate(row).then((res) => {
      //   let { code, data } = res.data;
      //   if (code === "0") {
      //     //修改成功
      //   }
      // });
    },
    confirm(row, index) {
      // console.log(row);
      this.quoteSetUpdate(row).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          //修改成功
          this.getList(); //刷新列表
          this.getTreeList();
        }
      });
    },
    editZk(row, index) {
      //编辑
      // console.log(row);
      row.disabled = false;
    },
    del(row, index) {
      //删除
      // row.disabled = false;
      this.quoteAgentDelete(row.id).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          //修改成功
          this.getList(); //刷新列表
          this.getTreeList();
        }
      });
    },
    addAgent() {
      this.show = true;
      console.log(1);
    },
    addtip() {
      this.addshow = true;
    },
    addSuccess() {
      //添加成功的回调
      this.getList(); //刷新列表
      this.getTreeList();
    },
  },
  created() {
    this.getTreeList();
    //对请求方法节流
    this.getList = throttle(this.getList);
    this.getList();
  },
};
</script>

<style scoped lang="less">
/deep/.el-tree-node__content {
  height: auto !important;
}

.a {
  height: 100%;
  width: 100%;
  display: flex;

  .a-left,
  .a-right {
    // overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
  }

  .a-left {
    flex: 1;
    border-right: 1px solid #f2f5fa;
    flex-direction: column;

    .a-r-title {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      border-bottom: 2px solid #f2f5fa;
    }
  }

  .a-right {
    flex: 2;
    display: flex;
    flex-direction: column;

    .a-r-title {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      border-bottom: 2px solid #f2f5fa;
    }

    .a-content {
      flex: 1;
      overflow-y: auto;

      .a-list {
        padding: 10px;
        border-bottom: 2px solid #f2f5fa;

        .a-i-discount {
          display: flex;
          align-items: center;

          span {
            white-space: nowrap;
            padding-right: 20px;
          }

          p {
            color: #616161;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .a-img {
    margin-left: 20%;
    //position: absolute;
    //top: 10px;
    //right: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
</style>
