<template>
  <!-- 商品信息 -->
  <div style="display: flex;">
    <div style="padding: 20px 20px 0;font-weight: bold;line-height: 22px;">
      <div v-for="(item, index) in quoteSwitch.data1" :key="index">{{ item.text }} {{ item.value }}</div>
    </div>
    <div style="padding: 20px 20px 0;font-weight: bold;line-height: 22px;">
      <div v-for="(item, index) in quoteSwitch.data2" :key="index">{{ item.text }} {{ item.value }}</div>
    </div>
    <div style="padding: 20px 20px 0;font-weight: bold;line-height: 22px;cursor: pointer" @click="$emit('changeCom', 'manage')">
      <div v-for="(item, index) in quoteSwitch.data3" :key="index"
        style="display: flex; align-items: center; justify-content: space-between;">
        <div>{{ item.text }}</div>
        <div style="padding: 5px;"><el-tag :type="['primary', 'success', 'danger'][index]">{{ item.value }}</el-tag></div>
      </div>
    </div>


    <div style="padding: 20px 20px 0;font-weight: bold;line-height: 22px;">
      <div v-for="(item, index) in quoteSwitch.userSets" :key="index"
        style="display: flex; align-items: center; justify-content: space-between;">
        <div>{{ ['报价转自动报价','采购转自动报价','询价转自动报价'][index]}}</div>

        
        <div style="padding: 5px;"> 
          <el-switch @change="changeQuoteSwitch($event,index)" :value="quoteSwitch.userSets[index].value == 1 ? true : false" inline-prompt active-text="开启" inactive-text="关闭" />

        </div>
      </div>
    </div>
  </div>




  <div class="p">
    <div class="p-title">

      <div style="display:flex;align-content: flex-start;">
        <div style="marginRight:20px">
          <el-select v-model="value" placeholder="请选择品牌" @change="optionsChange">
            <el-option label="所有品牌" value=""></el-option>
            <el-option v-for="item in options" :key="item.brandId" :label="item.brandName" :value="item.brandId">
            </el-option>
          </el-select>
        </div>
        <div style="marginRight:20px;width: 230px;">
          <!-- <el-input
            v-model="brandInput"
            placeholder="请输入品牌"
            @change="companyInput"
            clearable
          ></el-input> -->
          <!--          <el-select-->
          <!--              v-model="brandInput"-->
          <!--              placeholder="请选择系列"-->
          <!--              @change="companyInput"-->
          <!--          >-->
          <!--            <el-option-->
          <!--                v-for="item in seriesList"-->
          <!--                :key="item.id"-->
          <!--                :label="item.categoryName"-->
          <!--                :value="item.id"-->
          <!--            >-->
          <!--            </el-option>-->
          <!--          </el-select>-->
          <el-cascader :options="seriesList" placeholder="系列" :show-all-levels="false"
            style="position: relative;top:-5px;" filterable clearable v-model="brandInputTemp" @change="seriesChange" />
        </div>
        <div style="marginRight:20px;marginTop:-5px;width: 230px;">
          <el-input v-model.trim="input" placeholder="请输入型号\订货号\商品名称" @change="changeInput" clearable></el-input>
        </div>
        <div style="marginRight:20px">
          <el-date-picker style="width:400px;margin-right:20px" class="date-picker-box" v-model="queryDate"
            type="daterange" :clearable="true" unlink-panels append-to-body="true" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate" :shortcuts="shortcuts"
            format="YYYY/MM/DD" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
        </div>
        <div style="marginRight:20px">
          <el-select v-model="stateValue" placeholder="请选择状态" @change="stateChange">
            <el-option label="所有状态" value=""></el-option>
            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        
        <div style="marginRight:20px">
          <el-select v-model="sourceType" placeholder="请选择状态" @change="sourceTypeChange">
            <el-option label="所有来源" value=""></el-option>
            <el-option v-for="item in sourceTypeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="search">搜索</el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="resize">重置</el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="addGoods">新增
          </el-button>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end">
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="toImport">导入
          </el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="editDoods">批量修改
          </el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="upperDoods">批量上架
          </el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="lowerDoods">批量下架
          </el-button>
        </div>
        <div style="marginRight:20px">
          <el-button type="primary" size="mini" @click="deleteDoods">批量删除
          </el-button>
        </div>
      </div>
    </div>
    <lida-table :cutHeight="200" :data="list" border :total="total" :page="page" :pageSize="pageSize"
      @change="pageChange" ref="table" @selection-change="selectionChange">
      <!-- <el-table-column type="index" width="55" fixed label="序号" />
      <lida-table-column label="产品信息" fixed width="300">
        <template #default="scope">
          <div class="g-info">
            <div class="g-icon">
              <img :src="scope.row.src" />
            </div>
            <div>
              <div>
                {{ scope.row.productName }}
                <div class="label" :title="scope.row.brandName">
                  {{ scope.row.brandName }}
                </div>
              </div>
              <div class="g-r-data">
                {{ scope.row.barCode || "无" }}
                <span>|</span>
                {{ scope.row.seriesName || "无" }}
                <span>|</span>
                {{ scope.row.skuNumber || "无" }}
              </div>
            </div>
          </div>
        </template>
</lida-table-column> -->
      <el-table-column type="selection" width="55" /> 
      <lida-table-column label="产品信息">
          <template #default="scope">
            <div class="g-info" style="display: flex;justify-content: center;">
              <div class="g-info-right" style="display: flex;flex-direction: column;align-items: center;">
                <div class="g-info-row g-info-title w-150px mb-2" v-if="scope.row.id">
                  {{ scope.row.productName }} | {{ scope.row.seriesName }}
                </div>
                <div class="g-info-row" v-if="scope.row.id">
                  <span>参数:</span>
                  <p>{{ scope.row.barCode }}</p>
                </div>
                <div class="g-info-row">
                  <span>订货号:</span>
                  <p>{{ scope.row.articleNo }}</p>
                </div> 
                <div class="g-info-row">
                  <el-tag>{{ scope.row.brandName }}</el-tag>
                </div>
              </div>
            </div>
          </template>
        </lida-table-column>

      <lida-table-column label="添加时间" prop="createTime" />

      <lida-table-column label="品牌" prop="brandName" />


 

      
      <lida-table-column label="面价" prop="guidePrice" width="100" />
      <lida-table-column label="采购折扣 ( % )" prop="purchaseDis" width="120" />
      <lida-table-column label="销售折扣 ( % )" prop="salesDis" width="120" />
      <lida-table-column label="销售价格" prop="salesPrice" width="120" />
      <lida-table-column label="状态" prop="state">
        <template #default="scope">
          <div>{{ scope.row.state == 'UPPER' ? '上架' : '下架' }}</div>
        </template>
      </lida-table-column>
      <lida-table-column label="渠道" prop="channel" width="100" />
      <lida-table-column label="数量" prop="qty" width="100" />

      <!-- <lida-table-column label="面价" prop="guidePrice" width="100" /> -->
      <!-- <lida-table-column label="采购单价" prop="purchasePrice" width="100" />
      <lida-table-column label="利润点数 ( % )" prop="profitSite" />
      <lida-table-column label="面价点数 ( % )" prop="guideSite" />
      <lida-table-column label="数量" prop="qty" />
      <lida-table-column label="地区" prop="shipArea" />
      <lida-table-column label="实际利润" prop="profitC" fixed="right" />
      <lida-table-column label="总利润" prop="totalProfitC" fixed="right" /> -->
      <!-- <template #default="scope">
						<div class="t-profit">
							<div class="t-profit-i" :info="scope.row">
								<span>采购折扣 ( % ):</span>
								<p>{{scope.row.purchaseDis}}</p>
							</div>
							<div class="t-profit-i">
								<span>销售折扣 ( % ):</span>
								<p>{{scope.row.salesDis}}</p>
							</div>
							<div class="t-profit-i">
								<span>销售价格:</span>
								<p>{{scope.row.salesPrice}}</p>
							</div>
							<div class="t-profit-i">
								<span>实际利润:</span>
								<p>{{scope.row.profitC}}</p>
							</div>
							<div class="t-profit-i">
								<span>总利润:</span>
								<p>{{scope.row.totalProfitC}}</p>
							</div>
						</div>
					</template> -->
      <!-- </lida-table-column>
				 -->
      <lida-table-column label="操作" fixed="right">
        <template #default="scope">
          <table-menus :row="scope.row" :index="scope.$index" :data="operation" @command="commands" />
        </template>
      </lida-table-column>
    </lida-table>


    <importFileDialog @search="search" v-model="importFileShow" @success="importFileSuccess" />
    <!-- 新增弹框 -->
    <add-product-dialog :ids="ids" v-model="show" @success="addSuccess" />
    <!-- 编辑弹框 -->

    <edit-product-offer :info="activeInfo" v-model="editShow" @success="editSuccess" />
    <batch-product-offer :info="batchInfo" v-model="batchShow" @success="editSuccess" />
  </div>
</template>

<script>
import addProductDialog from "../dialog/addProduct.vue"; //新增商品弹框
import importFileDialog from "@/components/importFile/index.vue"; //导入商品弹框
import { mapActions } from "vuex";
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import tableMenus from "@/components/publicComponent/tableMenus.vue";
import { ElMessage } from "element-plus";
import editProductOffer from "../dialog/editProductOffer.vue"; //编辑弹框
import batchProductOffer from "../dialog/batchProductOffer.vue"; //编辑弹框
import moment from "moment";
import { handleTreeData } from "@/common/js/common";

moment.locale('zh-cn')
export default {
  data() {
    return {
      list: [], //商品列表
      show: false, //新增弹框的隐藏显示
      editShow: false, //编辑弹框的隐藏显示
      ids: [], //需要置灰的ids
      page: 1, //当前页数
      pageSize: 50, //每页显示的数量
      total: 0, //数据总数
      operation: [{ name: "编辑" }, { name: "删除" }],
      value: "",
      input: "",
      brandInputTemp: '',
      importFileShow: false,
      queryDate: [
        // moment()
        //     .subtract(1, "month")
        //     .format("YYYY-MM-DD 00:00:00"),
        // moment().format("YYYY-MM-DD 23:59:59"),
      ],
      options: [],
      stateOptions: [
        { label: '上架', value: 'UPPER' },
        { label: '下架', value: 'LOWER' },
      ],
      sourceTypeOptions:[
        { label: '新增转自动报价', value: 1 },
        { label: '导入转自动报价', value: 2 },
        { label: '报价转自动报价', value: 3 },
        { label: '询价转自动报价', value: 4 },
        { label: '采购转自动报价', value: 5 },
      ],
      stateValue: '',
      sourceType:'',
      activeInfo: {}, //当前选中的数据
      brandInput: "",
      batchShow: false,
      batchInfo: [],
      seriesList: [],
      shortcuts: [
        {
          text: '今日',
          value: () => {
            const start = moment().format('YYYY-MM-DD 00:00:00')
            const end = moment().format('YYYY-MM-DD 23:59:59')
            return [start, end]
          },
        },
        {
          text: '昨日',
          value: () => {
            const start = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
            const end = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
            return [start, end]
          },
        },
        {
          text: '本周',
          value: () => {
            const start = moment(moment().week(moment().week()).startOf('week').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().week(moment().week()).endOf('week').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '上周',
          value: () => {
            const start = moment(moment().week(moment().week() - 1).startOf('week').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().week(moment().week() - 1).endOf('week').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本月',
          value: () => {
            const start = moment(moment().month(moment().month()).startOf('month').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().month(moment().month()).endOf('month').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '上月',
          value: () => {
            const start = moment(moment().month(moment().month() - 1).startOf('month').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().month(moment().month() - 1).endOf('month').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本季',
          value: () => {
            const start = moment(moment().quarter(moment().quarter()).startOf('quarter').valueOf()).format('YYYY/MM/DD 00:00:00');
            const end = moment(moment().quarter(moment().quarter()).endOf('quarter').valueOf()).format('YYYY/MM/DD 23:59:59');
            return [start, end]
          },
        },
        {
          text: '本年',
          value: () => {
            const start = moment(moment().year(moment().year()).startOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            const end = moment(moment().year(moment().year()).endOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            return [start, end]
          },
        },
        {
          text: '上年',
          value: () => {
            const start = moment(moment().year(moment().year() - 1).startOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            const end = moment(moment().year(moment().year() - 1).endOf('year').valueOf()).format('YYYY/MM/DD HH:mm:ss');
            return [start, end]
          },
        },
      ],
      quoteSwitch: {}
    };
  },
  components: {
    addProductDialog,
    tableMenus,
    editProductOffer,
    batchProductOffer,
    importFileDialog
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "quoteSetSkuPage", //获取商品列表
      "quoteSetSkuSkuIds", //获取已设置的商品ids
      "quoteSetSkuRemove", //删除自动报价商品
      'batchUpperOrLower',//批量上架
      'removeBatch',//批量删除
      "countDataAndQuoteSwitch",
      "adminSetUserSet"
    ]),
    ...mapActions("purchaseMudule", [
      "bannerCategoryCurrent",
      "listSkuCategoryForFirst", //顶级分类请求
      "listSkuCategoryForChildren", //获取子集分类
    ]),
    // getOptions() {
    //   //品牌列表
    //   this.listSkuCategoryForFirst({}).then((res) => {
    //     let { data, code } = res.data;
    //     // console.log(data.data, code);
    //     if (code === "0" && data.data.length) {
    //       //   console.log(data);
    //       this.options = data.data;
    //     }
    //   });
    // },
    getCountDataAndQuoteSwitch() {
      this.countDataAndQuoteSwitch().then((res) => {
        let { data, code } = res.data
        if (code === "0") {
          data.userSets = data.userSets.map(item=>{
            item.value = item.value == "1" ? true : false;
            return item
          })
          this.quoteSwitch = data
        }
      });
    },
    importFileSuccess() {
      this.importFileShow = false
    },
    changeQuoteSwitch(e,index){
      this.quoteSwitch.userSets[index].value = e ? 1 : 0

      this.adminSetUserSet(this.quoteSwitch.userSets[index]).then((res) => {
        let { data, code } = res.data
        if (code === "0") {
         this.getCountDataAndQuoteSwitch()
        }
      });
    },
    toImport() {
      this.importFileShow = true

      // this.$router.push('/components/importFile')

    },
    seriesChange(val) {
      if (!val) {
        this.brandInput = ''
      } else {
        this.brandInput = val[Object.keys(val).length - 1]
      }
      this.search()
    },
    getBrand() {
      //请求品牌列表
      this.bannerCategoryCurrent({
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        let { data, code } = res.data
        if (code === "0" && data.length) {
          // data = getLetterSortData(data, "brandName");
          // this.options=new Set(arr)
          let map = new Map();
          for (let item of data) {
            if (item.brandId) {
              if (!map.has(item.brandId)) {
                map.set(item.brandId, item);
              }
            }
          }
          this.options = [...map.values()];

          // data.map((item, index) => {
          //   item.id = "a_" + (index + 1);
          //   item.label = item.letter;
          //   if (item.children) {
          //     item.children.map((son) => {
          //       son.label = son.categoryName;
          //       delete son.children;
          //     });
          //   }
          // });
          //默认选中第一个
          // this.brandList = data;
          // this.filter.brandId = data[0].children[0].id;
          //根据厂家id获取系列
          this.getSeriesList("filter", true); //获取当前系列
        }
      });
      // this.getUserHomeOperatingTrend()
    },
    optionsChange(val) {
      this.value = val;
      this.search();
      this.getSeriesList()
    },
    stateChange(val) {
      this.stateValue = val;
      this.search();
    },
    sourceTypeChange(val) {
      this.sourceType = val;
      this.search();
    },
    
    changeInput(val) {
      this.input = val;
      this.search();
    },
    companyInput(val) {
      this.brandInput = val;
      this.search();
    },
    changeDate() {
      this.search();
    },
    search() {
      // console.log(this.queryDate);
      this.quoteSetSkuPage({
        pageSize: this.pageSize,
        pageNo: this.page,
        brandId: this.value,
        seriesId: this.brandInput,
        startDate: this.queryDate[0],
        endDate: this.queryDate[1],
        keyword: this.input,
        state: this.stateValue,
        sourceType: this.sourceType
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          data.rows.map((item) => {
            return offerProfitCompute(item);
          });
          this.list = data.rows;
          this.total = data.totalCount;
        }
      });
    },
    resize() {
      this.search();
    },
    addGoods() {
      //新增商品的点击事件
      this.show = true;
    },
    getList() {
      let arr = [];
      //获取商品列表
      this.quoteSetSkuPage({
        pageSize: this.pageSize,
        pageNo: this.page,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          // data.rows.map((item) => {
          //   arr.push(item);
          //   // this.options=new Set(arr)
          //   let map = new Map();
          //   for (let item of arr) {
          //     if (!map.has(item.brandId)) {
          //       map.set(item.brandId, item);
          //     }
          //   }
          //   this.options = [...map.values()];
          //   return offerProfitCompute(item);
          // });
          this.list = data.rows;
          this.total = data.totalCount;
        }
      });
    },
    getIds() {
      //获取ids集合
      this.quoteSetSkuSkuIds({}).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.ids = data;
        }
      });
    },
    pageChange(page, pageSize) {
      //分页的改变事件
      this.page = page;
      this.pageSize = pageSize;
      this.search();
    },
    addSuccess() {
      //添加成功后刷新页面数据
      this.search();
      this.getIds();
    },
    editSuccess() {
      this.batchInfo = []
      //修改成功的回调
      this.search(); //刷新页面数据
    },
    //批量修改
    editDoods() {
      this.batchShow = true;
      // this.batchInfo = this.list;
    },
    // 批量上架
    upperDoods() {
      if (this.batchInfo.length) {
        let arr = this.batchInfo.map(v => v.id)
        this.batchUpperOrLower({ idList: arr, state: 'UPPER' }).then((res) => {
          let { data, code } = res.data;
          if (code == '0') {
            ElMessage({
              type: "success",
              message: "上架成功",
            });
            //刷新列表
            this.search();
          }
        })
      } else {
        ElMessage({
          type: "error",
          message: "请勾选产品",
        });
      }

    },
    // 批量下架
    lowerDoods() {
      if (this.batchInfo.length) {
        let arr = this.batchInfo.map(v => v.id)
        this.batchUpperOrLower({ idList: arr, state: 'LOWER' }).then((res) => {
          let { data, code } = res.data;
          if (code == '0') {
            ElMessage({
              type: "success",
              message: "下架成功",
            });
            //刷新列表
            this.search();
          }
        })
      } else {
        ElMessage({
          type: "error",
          message: "请勾选产品",
        });
      }

    },
    // 批量删除
    deleteDoods() {
      if (this.batchInfo.length) {
        let arr = this.batchInfo.map(v => v.id)
        this.removeBatch({ idList: arr }).then((res) => {
          let { data, code } = res.data;
          if (code == '0') {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            //刷新列表
            this.search();
          }
        })
      } else {
        ElMessage({
          type: "error",
          message: "请勾选产品",
        });
      }
    },
    commands(info) {
      if (info.dropdownName === "编辑") {
        this.editShow = true;
        this.activeInfo = info.row;
      } else if (info.dropdownName === "删除") {
        this.quoteSetSkuRemove(info.row.id).then((res) => {
          let { data, code } = res.data;
          if (code === "0") {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            //刷新列表
            this.search();
          }
        });
      }
    },
    getSeriesList() {
      // 获取系列列表
      if (this.value) {
        this.listSkuCategoryForChildren({
          parentId: this.value,
        }).then((res) => {
          let { data, code } = res.data.data;
          if (code === "0" && data.length) {
            this.seriesList = handleTreeData(data, "categoryName", 'id');
          }
        });
      }
    },
    //多选框
    selectionChange(val) {
      this.batchInfo = [...val]
      // console.log(this.batchInfo)
    },
  },
  created() {
    this.getBrand()
    this.search();
    this.getIds();
    // this.getOptions();
    this.getCountDataAndQuoteSwitch()
  },
};
</script>

<style scoped lang="less">
.p {
  box-sizing: border-box;
  padding: 15px;
}

.p-title {
  padding: 10px 0;

  .p-t-left {
    margin-bottom: 20px;
    color: red;
  }
}

.g-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .g-icon {
    img {
      width: 60px;
      height: 60px;
    }
  }

  .g-right {
    flex: 1;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .g-r-title {
      font-size: 14px;
      display: flex;

      .label {
        max-width: 100px;
        background: @dh-color;
        border-radius: 0 10px 10px 10px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        padding: 0 8px;
        margin-left: 10px;
        height: 24px;
        box-sizing: border-box;
      }
    }

    .g-r-data {
      color: #666666;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin: 0 10px;
      }
    }
  }
}

.t-profit {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .t-profit-i {
    display: flex;
    padding-bottom: 5px;

    span {
      width: 100px;
      color: #999999;
    }
  }
}
.g-info-row{
  text-align: center;
}
</style>
