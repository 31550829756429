<template>
  <el-dialog width="1000px">
    <div class="a">
      <div class="a-title">添加</div>
      <div class="a-content">
        <div class="a-left">
          <div class="a-item">
            <span><i>*</i>选择代理品牌</span>
            <tree-select
              style="flex: 1;"
              placeholder="请选择品牌"
              v-model="brandId"
              :clearable="false"
              :options="brandList"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              :key="brandId"
              @select="(node) => treeChange(node, 'brandId')"
              :flatten-search-results="true"
            />
          </div>
          <!-- <div class="a-item">
						<span><i>*</i>选择代理品牌系列</span>
						<tree-select
							style="flex: 1;"
							placeholder="请选择系列"
							:clearable="false"
							:options="seriesList"
							v-model="seriesId"
							:key="seriesId"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							@select="(node) => treeChange(node, 'seriesId')"
							:flatten-search-results="true"/>
					</div> -->
          <div class="a-item">
            <span><i>*</i>代理时间</span>
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="flex: 1;"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="a-right">
          <div class="a-r-title"><i>*</i>代理证书</div>
          <el-upload
            class="avatar-uploader"
            :action="getAction"
            :headers="getHeaders"
    accept=".jpeg,.png,.pdf"
:on-remove="removeFile"
:limit="10"
            :on-success="onSuccess"
            :before-upload="beforeUpload"
          >
            <div class="up-img">
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
              <p class="iconfont icon-jiahao"></p>
            </div>
            <template #tip>
              <div class="el-upload__tip">
                上传格式为JPG/PNG/PDF
              </div>
            </template>
          </el-upload>
        </div>
      </div>
      <div class="a-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close"
          >取消</el-button
        >
        <el-button
          style="margin-left: 20px;"
          size="mini"
          type="primary"
          @click="confirm"
          >确认</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getLetterSortData, //按字母排序
  handleTreeData, //处理树形数据
  getBase64,
} from "@/common/js/common.js";
import { mapActions, mapState } from "vuex";
import request from "@/common/js/request.js";
import api from "@/common/js/api.js";
import { ElMessage } from "element-plus";
import moment from "moment";
export default {
  data() {
    return {
      brandId: null, // 厂家id
      seriesId: null, // 系列id
      seriesList: [], //系列列表
      brandList: [], //藏家列表
      time: [], //代理时间
      imageUrl: null, //图片临时路径
      ids: [], //图片的ids
    };
  },
  methods: {
    ...mapActions("purchaseMudule", [
      "listSkuCategoryForFirst", //顶级分类请求
      "listSkuCategoryForChildren", //获取子集分类
    ]),
    ...mapActions("mysaleMudule", [
      "quoteSetCreate", //新增代理设置
      "quoteAgentList", //品牌代理列表
      "quoteAgentCreate",
    ]),
    close() {
      //关闭弹框
      this.$emit("update:modelValue", false);
    },
    removeFile(_,uploadFiles){
      this.ids=uploadFiles.map(ex=>ex?.response?.data?.fileId)
    },
    getBrandList() {
      //获取品牌列表
      this.listSkuCategoryForFirst({
        pageNo: 1,
        pageSize: 99999,
      }).then((res) => {
        let { data, code } = res.data.data;
        if (code === "0" && data.length) {
          data = getLetterSortData(data, "initialCode");
          data.map((item, index) => {
            item.id = "a_" + (index + 1);
            item.label = item.letter;
            if (item.children) {
              item.children.map((son) => {
                son.label = son.categoryName;
                delete son.children;
              });
            }
          });
          //默认选中第一个
          this.brandList = data;
          this.brandId = data[0].children[0].id;
          //根据厂家id获取系列
          // this.getSeriesList(); //获取当前系列
        }
      });
    },
    getSeriesList() {
      //获取系列列表
      this.listSkuCategoryForChildren({
        parentId: this.brandId,
      }).then((res) => {
        let { data, code } = res.data.data;
        if (code === "0" && data.length) {
          this.seriesList = handleTreeData(data, "categoryName");
          this.seriesId = this.getOneId(this.seriesList);
        }
      });
    },
    //获取第一个id
    getOneId(data, text) {
      if (data.length && data[0].children && data[0].children.length) {
        return this.getOneId(data[0].children);
      } else {
        return data[0].id;
      }
    },
    treeChange(node, type) {
      //树形选择的事件
      this[type] = node.id;
      if (type === "brandId") {
        //厂家切换
        this.getSeriesList();
      }
    },
    confirm() {
      //确认的点击事件
      var text = "";
      if (!this.brandId) {
        text = "请选择代理品牌";
      }
      // else if(!this.seriesId){
      // 	text = "请选择代理品牌系列";
      // }
      else if (!this.time.length) {
        text = "请选择代理时间";
      } else if (!this.ids.length) {
        text = "请上传代理证书";
      }
      if (text) {
        ElMessage({
          type: "warning",
          message: text,
        });
      } else {
        this.quoteAgentCreate({
          brandId: this.brandId,
          // belongId:this.seriesId,
          certificateFilesIds: this.ids,
          // oldCertificateFilesIds :[],
          termStart: moment(this.time[0]).format("YYYY-MM-DD"),
          termEnd: moment(this.time[1]).format("YYYY-MM-DD"),
          type: "AGENT",
        }).then((res) => {
          let { code, data } = res.data;
          if (code === "0") {
            ElMessage({
              type: "success",
              message: "添加成功",
            });
            //关闭弹框
            location.reload();
            this.close();
            this.$emit("success");
          }
        });
      }
    },
    onSuccess(response, file, fileList) {
      //监听文件上传成功
      this.ids = [...this.ids, response.data.fileId]; //图片id
      getBase64(file.raw).then((res) => {
        this.imageUrl = res;
        // console.log(res);
      });
    },
    beforeUpload(file) {
      let isJPG =false
        // file.type === "image/jpeg" || "application/pdf" || "image/png";
		console.log(isJPG);
		if(file.type === "image/jpeg"){
			isJPG=true
		}else if(file.type === "application/pdf"){
			isJPG=true
		}else if(file.type === "image/png"){
			isJPG=true
		}else{
			isJPG=false
		}
      if (!isJPG) {
        ElMessage({
          type: "error",
          message: "上传格式为JPG/PNG/PDF!",
        });
        return false;
      }
      return isJPG;
    },
  },
  created() {
    this.getBrandList();
  },
  computed: {
    ...mapState([
      "token", //获取token
    ]),
    getAction() {
      //上传文件地址
      return request.defaults.baseURL + api.uploadFile + "?fileType=CUSTOMER";
    },
    getHeaders() {
      //请求头部
      return {
        Authorization: this.token || null,
      };
    },
  },
};
</script>

<style scoped lang="less">
.a {
  font-size: 14px;
  padding: 20px;
  .a-title {
    font-weight: bold;
    padding: 0 20px 20px 0;
  }
  .a-footer {
    display: flex;
    justify-content: center;
  }
  .a-content {
    display: flex;
    align-items: stretch;
  }
  .a-left {
    flex: 5;
    .a-item {
      display: flex;
      align-items: center;
      padding-bottom: 60px;
      span {
        width: 140px;
        margin-right: 10px;
        text-align: right;
        i {
          margin-right: 4px;
          line-height: 20px;
          color: #ff1a1d;
        }
      }
    }
  }
  .a-right {
    flex: 3;
    box-sizing: border-box;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .up-img {
      width: 180px;
      height: 180px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbfdff;
      border-radius: 10px;
      border: 1px solid #eeeeee;
      overflow: hidden;
      p {
        font-size: 40px;
        color: #999999;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .a-r-title {
      text-align: center;
      padding-bottom: 20px;
      i {
        color: #ff1a1d;
        margin-right: 4px;
      }
    }
  }
}
</style>
