<template>
    <el-dialog width="1300px" top="6vh">
        <div class="a">
            <div class="a-title">添加商品</div>
            <div class="p">
                <div class="p-top">
                    <div style="marginRight:2%;width:100%">
                        <el-input
                                v-model="value"
                                :rows="10"
                                type="textarea"
                                placeholder="请输入您想查询的信息"
                        />
                    </div>
                    <!-- <div>
                      <span>请想查询的库存：</span>
                      <el-input-number v-model="num" :min="1" :max="10" @change="handleChange" />
                    </div> -->
                </div>
                <div style="padding: 10px 0 20px 0;display: flex;">
                    <el-button size="mini" @click="search" type="primary">查询</el-button>
                    <el-button size="mini" @click="look" type="primary"
                    >查看有库存的商品
                    </el-button
                    >
                    <el-button size="mini" @click="visible = true" type="primary">批量修改</el-button>
                    <div style="color: #999999;margin-left: 20px;font-size: 12px;">
                        批量添加订货号（型号）和数量，用空格隔开，也可从Excel中复制粘贴，然后按查询。
                    </div>
                </div>
                <!-- 商品信息 -->
                <lida-table
                        :pagination="false"
                        :cutHeight="150"
                        :data="data"
                        border
                        ref="table"
                        @selection-change="selectionChange"
                >
                    <el-table-column
                            type="selection"
                            width="50"
                            align="center"
                    />
                    <el-table-column
                            type="index"
                            label="序号"
                            width="50"
                            align="center"
                    />
                    <lida-table-column label="品牌" :min-width="180" align="center">
                        <template #default="scope">
                            <el-tag>{{ scope.row.brandName }}</el-tag>
                        </template>
                    </lida-table-column>
                    <lida-table-column
                            label="商品名称"
                            prop="productName"
                            :min-width="140"
                            align="center"
                    />
                    <lida-table-column
                            label="订货号"
                            prop="articleNo"
                            :min-width="140"
                            align="center"
                    />
                    <lida-table-column
                            label="型号"
                            prop="barCode"
                            :min-width="140"
                            align="center"
                    />
                    <lida-table-column label="面价" prop="guidePrice" :min-width="200">
                        <template #default="scope">
                            <el-input
                                    @blur="priceBlur(scope.row)"
                                    v-model="scope.row.guidePrice"
                                    @input="inputLv(scope.row)"
                                    placeholder="请输入面价"
                            />
                        </template>
                    </lida-table-column>


                    <!-- <lida-table-column label="加点(折扣)" :min-width="150">
                      <template #header="{column}">
                        <span style="color:red">{{column.label}}</span>
                      </template>
                      <template #default="scope">
                        <div class="t-row">
                          <el-input-number
                              v-model="scope.row.guideSite"
                              size="small"
                              @change="inputLv(scope.row,'guideSite',)"
                          />
                        </div>
                      </template>
                    </lida-table-column> -->

                    <!-- <lida-table-column label="利润点数 ( % )">
                      <template #header="{column}">
                        <span style="color:red">{{column.label}}</span>
                      </template>
                      <template #default="scope">
                        <div class="t-row">
                          <el-input-number
                              v-model="scope.row.profitSite"
                              size="small"
                              @change="inputLv(scope.row,'profitSite',)"
                          />
                        </div>
                      </template>
                    </lida-table-column> -->

                    <lida-table-column label="销售折扣 ( % )">
                        <template #default="scope">
                            <div class="t-row">
                                <el-input-number
                                        v-model="scope.row.salesDis"
                                        size="small"
                                        @change="inputLv(scope.row, 'salesDis')"
                                />
                            </div>
                        </template>
                    </lida-table-column>

                    <lida-table-column label="销售价格">
                        <template #default="scope">
                            <div class="t-row">
                                <el-input-number
                                        v-model="scope.row.salesPrice"
                                        size="small"
                                        @change="inputLv(scope.row, 'salesPrice')"
                                />
                            </div>
                        </template>
                    </lida-table-column>

                    <lida-table-column label="数量" prop="qty">
                        <template #default="scope">
                            <el-input-number
                                    @change="inputLv(scope.row)"
                                    v-model="scope.row.qty"
                                    :min="0"
                            />
                        </template>
                    </lida-table-column>
                    <lida-table-column label="采购折扣 ( % )">
                        <template #default="scope">
                            <div class="t-row">
                                <el-input-number
                                        v-model="scope.row.purchaseDis"
                                        size="small"
                                        @change="inputLv(scope.row, 'purchaseDis')"
                                />
                            </div>
                        </template>
                    </lida-table-column>

                    <lida-table-column label="采购单价">
                        <template #default="scope">
                            <el-input-number
                                    v-model="scope.row.purchasePrice"
                                    size="small"
                                    @change="inputLv(scope.row, 'purchasePrice')"
                            />
                        </template>
                    </lida-table-column>

                    <lida-table-column label="生产日期" :min-width="120" align="center">
                        <template #default="scope">
                            <el-date-picker
                                    v-model="scope.row.manufactureDate"
                                    type="date"
                                    placeholder="请选择生产日期"
                            >
                            </el-date-picker>
                        </template>
                    </lida-table-column>
                    <lida-table-column label="渠道">
                        <template #default="scope">
                            <el-select v-model="scope.row.channel" placeholder="请选择渠道">
                                <el-option
                                        v-for="item in channel"
                                        :key="item.title"
                                        :label="item.title"
                                        :value="item.title"
                                ></el-option>
                            </el-select>
                        </template>
                    </lida-table-column>
                    <lida-table-column label="地区">
                        <template #default="scope">
                            <el-cascader
                                    v-model="scope.row.shipArea"
                                    :options="areaInfo"
                                    :props="{ expandTrigger: 'hover' }"
                                    style="width: 100%;"
                            ></el-cascader>
                        </template>
                    </lida-table-column>

                    <!--          <lida-table-column label="实际利润" fixed="right" :min-width="160" align="center">-->
                    <!--            <template #default="scope">-->
                    <!--              <div class="t-profit">-->
                    <!--                <div class="t-profit-i">-->
                    <!--                  <span>实际利润:</span>-->
                    <!--                  <span>{{ scope.row.profitC }}</span>-->
                    <!--                </div>-->
                    <!--              </div>-->
                    <!--            </template>-->
                    <!--          </lida-table-column>-->
                    <!--          <lida-table-column label="总利润" fixed="right" :min-width="160" align="center">-->
                    <!--            <template #default="scope">-->
                    <!--              <div class="t-profit">-->
                    <!--                <div class="t-profit-i">-->
                    <!--                  <span>总利润:</span>-->
                    <!--                  <span>{{ scope.row.totalProfitC }}</span>-->
                    <!--                </div>-->
                    <!--              </div>-->
                    <!--            </template>-->
                    <!--          </lida-table-column>-->
                    <lida-table-column label="操作" fixed="right" width="150">
                        <template #default="scope">
                            <el-button size="mini" @click="deleteRow(scope)" type="text"
                            >移除
                            </el-button
                            >
                            <el-button v-if="!scope.row.id" size="mini"
                                       @click="errorCorrection(scope.row.articleNo,scope.$index)"
                                       type="text"
                            >替换
                            </el-button
                            >
                        </template>
                    </lida-table-column>
                </lida-table>
            </div>
            <div class="a-footer">
                <el-button style="margin-right: 20px;" size="mini" @click="close"
                >取消
                </el-button
                >
                <el-button
                        style="margin-left: 20px;"
                        type="primary"
                        size="mini"
                        @click="confirm"
                >确认
                </el-button
                >
            </div>
        </div>

        <el-dialog v-model="dialogTableVisible">
            <div class="a">
                <div class="a-title">查看有库存的商品</div>
                <div style="display:flex">
                    <!-- <el-tree-select v-model="brandValue" :data="brandData" /> -->
                    <div>
                        <tree-select
                                style="width: 320px;"
                                placeholder="请选择品牌"
                                v-model="ruleForm.brandId"
                                :clearable="false"
                                :options="brandData"
                                :disable-branch-nodes="true"
                                :searchable="true"
                                :defaultExpandLevel="10"
                                :key="ruleForm.brandId"
                                @select="(node) => treeChange(node, 'brandId')"
                                :flatten-search-results="true"
                        />
                    </div>
                    <div style="marginLeft:2%;marginTop:-5px">
                        <el-input
                                v-model="keywordInput"
                                placeholder="请输入关键字"
                                style="width:150px"
                                @change="keyChange"
                        />
                    </div>
                    <div style="marginLeft:2%;marginTop:-5px">
                        <el-button type="primary" @click="searchValue">重置</el-button>
                    </div>
                </div>
                <el-table :data="gridData" @selection-change="handleSelectionAll">

                    <el-table-column type="selection" width="55"/>
                    <el-table-column type="index" width="55" label="序号"/>
                    <el-table-column property="articleNo" label="订货号"/>
                    <el-table-column property="barCode" label="型号 "/>
                    <el-table-column property="brandName" label="品牌名称"/>
                    <el-table-column property="guidePrice" label="面价/指导价"/>
                    <el-table-column property="productName" label=" 产品 "/>
                    <el-table-column property="quantity" label="  库存数量  "/>
                    <el-table-column property="seriesName" label="  系列名称  "/>
                    <el-table-column property="quantity" label="  库存数量  "/>
                </el-table>
                <el-pagination style="float:right;marginRight:2%"
                               layout="prev, pager, next"
                               @current-change="changcurrent"
                               :total="total">
                </el-pagination>
                <div class="a-footer">
                    <el-button
                            style="margin-right: 20px;"
                            size="mini"
                            @click="dialogTableVisible = false"
                    >取消
                    </el-button
                    >
                    <el-button
                            style="margin-left: 20px;"
                            type="primary"
                            size="mini"
                            @click="selection"
                    >确认
                    </el-button
                    >
                </div>
            </div>
        </el-dialog>
        <el-dialog
                v-model="visible"
                width="30%"
        >
            <div class="a">
                <div class="a-title">批量设置</div>
                <div style="display:flex">
                    <!-- <el-tree-select v-model="brandValue" :data="brandData" /> -->
                    <el-form
                            :label-position="labelPosition"
                            label-width="140px"
                            :model="formLabelAlign"
                            style="max-width: 460px"
                    >
                        <!--    <el-form-item label="面价">-->
                        <!--       <el-input-->
                        <!--                v-model="formLabelAlign.guidePrice"-->
                        <!--                placeholder="请输入面价"-->
                        <!--              />-->
                        <!--    </el-form-item>-->
                        <el-form-item label="采购折扣 ( % )">
                            <el-input-number
                                    v-model="formLabelAlign.purchaseDis"
                                    size="small"
                            />
                        </el-form-item>
                        <!--    <el-form-item label="采购单价">-->
                        <!--     <el-input-number-->
                        <!--                v-model="formLabelAlign.purchasePrice"-->
                        <!--                size="small"-->
                        <!--              />-->
                        <!--    </el-form-item>-->
                        <el-form-item label="销售折扣 ( % )">
                            <el-input-number
                                    v-model="formLabelAlign.salesDis"
                                    size="small"
                            />
                        </el-form-item>
                        <!--     <el-form-item label="销售价格">-->
                        <!--      <el-input-number-->
                        <!--                  v-model="formLabelAlign.salesPrice"-->
                        <!--                  size="small"-->
                        <!--                />-->
                        <!--    </el-form-item>-->
                        <el-form-item label="数量">
                            <el-input-number

                                    v-model="formLabelAlign.qty"
                                    :min="0"
                            />
                        </el-form-item>
                        <el-form-item label="生产日期">
                            <el-date-picker
                                    v-model="formLabelAlign.manufactureDate"
                                    type="date"
                                    placeholder="请选择生产日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="渠道">
                            <el-select v-model="formLabelAlign.channel" placeholder="请选择渠道">
                                <el-option
                                        v-for="item in channel"
                                        :key="item.title"
                                        :label="item.title"
                                        :value="item.title"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="地区">
                            <el-cascader
                                    v-model="formLabelAlign.shipArea"
                                    :options="areaInfo"
                                    :props="{ expandTrigger: 'hover' }"
                                    style="width: 100%;"
                            ></el-cascader>
                        </el-form-item>
                    </el-form>

                </div>

                <div class="a-footer">
                    <el-button
                            style="margin-right: 20px;"
                            size="mini"
                            @click="visible = false"
                    >取消
                    </el-button
                    >
                    <el-button
                            style="margin-left: 20px;"
                            type="primary"
                            size="mini"
                            @click="edit"
                    >确认
                    </el-button
                    >
                </div>
            </div>
        </el-dialog>
        <el-dialog v-model="replaceVisible" width="60%">
            <div class="a">
                <div class="a-title">替换商品 (您输入的产品<label style="color: red">订货号/型号</label>可能有误，系统为您智能筛选出所输型号相近的产品供您选择。)
                </div>
                <el-row style="margin-bottom: 10px" :gutter="20">
                    <el-col :span="6">
                        <el-input v-model="currentReplaceArticleNo" placeholder="请输入" @input="getProduct"></el-input>
                    </el-col>
                </el-row>
                <lida-table
                        :data="replaceData"
                        border
                        style="width: 100%"
                        :cutHeight="0"
                        :page="pageNo"
                        :pageSize="pageSize"
                        :total="total"
                        :pagination="true"
                        ref="table"
                        @change="pageChange"
                >
                    <el-table-column property="brandName" align="center" label="品牌"></el-table-column>
                    <el-table-column property="productName" align="center" label="商品名称"></el-table-column>
                    <el-table-column property="barCode" align="center" label="型号"></el-table-column>
                    <el-table-column property="articleNo" align="center" label="订货号"></el-table-column>
                    <el-table-column property="guidePrice" align="center" label="面价"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button @click="replace(scope.row)">替换</el-button>
                        </template>
                    </el-table-column>
                </lida-table>
                <div class="a-footer">
                    <el-button
                            style="margin-right: 20px;"
                            size="mini"
                            @click="replaceVisible = false"
                    >关闭
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </el-dialog>

</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {ElMessage} from "element-plus";
    import {handleTreeData, getLetterSortData} from "@/common/js/common.js";
    import offerProfitCompute from "@/common/js/offerProfitCompute.js";
    import moment from "moment";
    import area from "@/common/json/area.json";
    import goodsMixin from "../../../purchaseMudule/mixins/goods.js";

    export default {
        data() {
            return {
                value: "", //搜索值
                data: [], //列表数据
                areaInfo: handleTreeData(area, "name", "name"),
                num: "0",
                dialogTableVisible: false,
                total: 0,
                pageNo: 1,
                pageSize: 10,
                gridData: [],
                row: {},
                rowData: [],
                brandValue: "",
                brandData: [],
                keywordInput: "",
                // ruleForm: {
                //   brandId: "",
                // },
                inpValue: "",
                visible: false,
                formLabelAlign: {},
                replaceVisible: false,
                currentReplaceIndex: '',
                currentReplaceArticleNo: '',
                replaceData: [],
                sectionData: [],

            };
        },
        props: {
            ids: {
                //需要置灰的商品ids
                type: Array,
                default: () => {
                    return [];
                },
            },
        },
        computed: {
            ...mapState("mysaleMudule", ["channel"]),
        },
        mixins: [goodsMixin],
        methods: {
            ...mapActions("purchaseMudule", ["skuPage"]),
            ...mapActions("stockModule", [
                "stockPage", //获取库存列表
            ]),
            ...mapActions("mysaleMudule", [
                "quoteSetSkuCreate", //新增自动报价商品
            ]),
            ...mapActions("purchaseMudule", [
                "listSkuCategoryForFirst", //顶级分类请求
                "listSkuCategoryForChildren", //获取子集分类
            ]),
            pageChange(page, pageSize) {
                //分页的改变事件
                this.pageNo = page;
                this.pageSize = pageSize;
                this.getProduct()
            },
            async getProduct() {
                this.replaceData = []
                if (!this.currentReplaceArticleNo) {
                    return
                }
                let result = await this.skuPage({
                    wd: this.currentReplaceArticleNo,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                })
                this.replaceData = result.data.data.rows
                this.total = result.data.data.totalCount
                this.pageSize = result.data.data.pageSize
                this.pageNo = result.data.data.pageNo
            },
            //纠错
            async errorCorrection(articleNo, index) {
                this.replaceVisible = true
                this.currentReplaceArticleNo = articleNo
                this.currentReplaceIndex = index
                await this.getProduct()
            },
            replace(row) {
                this.data[this.currentReplaceIndex] = {...row}
                this.replaceVisible = false
            },
            look() {
                this.dialogTableVisible = true;
                this.stockPage({pageNo: this.pageNo, pageSize: this.pageSize}).then((res) => {
                    // console.log(res.data.data.totalCount);
                    this.total = res.data.data.totalCount
                    this.gridData = [...res.data.data.rows];
                });
                this.getBrandValue();
            },
            changcurrent(val) {
                this.pageNo = val
                this.look()
            },
            keyChange(val) {
                // console.log(val);
                this.inpValue = val;
                this.stockPage({
                    pageNo: this.pageNo, pageSize: this.pageSize,
                    keyword: this.inpValue,
                }).then((res) => {
                    // console.log(res);
                    this.total = res.data.data.totalCount
                    this.gridData = [...res.data.data.rows];
                });
            },
            searchValue() {
                this.look()
            },
            getBrandValue() {
                this.listSkuCategoryForFirst({pageNo: this.pageNo, pageSize: this.pageSize}).then((res) => {
                    let {data, code} = res.data.data;
                    if (code === "0" && data.length) {
                        data = getLetterSortData(data, "initialCode");
                        data.map((item, index) => {
                            item.id = "a_" + (index + 1);
                            item.label = item.letter;
                            if (item.children) {
                                item.children.map((son) => {
                                    son.label = son.categoryName;
                                    delete son.children;
                                });
                            }
                        });
                        //默认选中第一个
                        this.brandData = data;
                        this.brandValue = data[0].children[0].id;
                        //根据厂家id获取系列
                        // this.getSeriesList(); //获取当前系列
                        // console.log(this.brandData, this.brandValue);
                    }
                });
            },
            treeChange(node, type) {
                this.stockPage({
                    pageNo: this.pageNo, pageSize: this.pageSize,
                    brandId: node.id,
                }).then((res) => {
                    // console.log(res);
                    this.total = res.data.data.totalCount
                    this.gridData = [...res.data.data.rows];
                });
            },
            handleSelectionAll(selection) {
                this.rowData = [...selection];
                // console.log(this.rowData);
            },
            selection() {
                // console.log({...this.row});
                let arr = [];
                let newData = [...this.data]
                if (this.rowData.length > 0) {
                    arr = [...this.rowData];
                } else {
                    arr.push({...this.row});
                }
                // console.log(arr);
                for (let i = 0; i < this.rowData.length; i++) {
                    if (newData.indexOf(this.rowData[i]) == -1) {
                        newData.push(this.rowData[i]);
                    }
                    // newData.push(arr[i])
                }
                this.data = newData.filter(function (item, index, self) {
                    return self.findIndex(el => el.id == item.id) === index
                })
                this.dialogTableVisible = false;
            },
            search() {
                //搜索的点击事件
                // console.log(this.value,this.num);
                if (this.value) {
                    this.skuPage({
                        wdBatch: this.value,
                        pageNo: this.pageNo, pageSize: this.pageSize
                    }).then((res) => {
                        let {code, data} = res.data;
                        if (code === "0") {
                            //请求成功
                            //每次搜索结果数据累加
                            data.rows.map((item) => {
                                item.qty = 1; //数量
                                item.manufactureDate = moment().format("YYYY-MM-DD"); //生产日期
                                item.channel = "现货"; //渠道
                                item.purchasePrice = ""; //采购单价
                                item.profitSite = ""; //利润点数
                                item.guideSite = ""; //面价点数
                                item.guidePrice = (item.guidePrice || 0).toFixed(2);
                                item.excGuidePrice = item.guidePrice; //保存原始价
                                item.disabled = this.ids.some((v) => v === item.id); //是否禁用
                                item.skuId = item.id
                                item.shipArea =
                                    typeof item.shipArea === "string"
                                        ? item.shipArea.split(",")
                                        : item.shipArea || [];
                                item.purchaseDis = ""; //采购折扣
                                item.salesDis = ""; //销售折扣
                                item.salesPrice = ""; //销售单价
                                item.profitC = ""; //实际利润
                            });
                            //渲染性能优化处理
                            //每次渲染10条数据searchGoodtree
                            let t = this,
                                len = 10;
                            for (var i = 0; i < Math.ceil(data.rows.length / len); i++) {
                                (function (i, data) {
                                    setTimeout(() => {
                                        var info = data.slice(i * len, (i + 1) * len);
                                        t.data = [...t.data, ...info];
                                    }, 1);
                                })(i, data.rows);
                            }
                        }
                    });
                } else {
                    ElMessage({
                        type: "warning",
                        message: "请输入查询条件",
                    });
                }
                //阻止浏览器默认事件
                let e = window.event || arguments[0];
                if (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13) {
                    e.returnValue = false;
                    return false;
                }
            },
            deleteRow(obj) {
                //删除商品的事件
                this.data.splice(obj.$index, 1);
            },
            close() {
                //关闭弹框
                this.$emit("update:modelValue", false);
            },
            priceBlur(row) {
                if (!row.guidePrice) {
                    row.guidePrice = row.excGuidePrice;
                }
            },
            confirm() {
                //确认的点击事件
                var data = JSON.parse(
                    JSON.stringify(this.data.filter((v) => !v.disabled))
                );
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        // if (!data[i].qty) {
                        //   return ElMessage({
                        //     type: "warning",
                        //     message: "请选择数量",
                        //   });
                        // }
                        // // else if (!data[i].channel) {
                        // //   return ElMessage({
                        // //     type: "warning",
                        // //     message: "请选择渠道",
                        // //   });
                        // // }
                        //     // else if (!data[i].purchasePrice) {
                        //     //   return ElMessage({
                        //     //     type: 'warning',
                        //     //     message: '请填写采购单价',
                        //     //   })
                        // // }
                        // // else if (!data[i].shipArea || !data[i].shipArea.length) {
                        // //   return ElMessage({
                        // //     type: "warning",
                        // //     message: "请选择地区",
                        // //   });
                        // // }
                        // else if (!data[i].salesDis) {
                        //   return ElMessage({
                        //     type: "warning",
                        //     message: "请填写销售折扣",
                        //   });
                        // } else if (!data[i].salesPrice) {
                        //   return ElMessage({
                        //     type: "warning",
                        //     message: "请填写销售价格",
                        //   });
                        // }
                        if (!data[i].salesDis || !data[i].salesPrice || !data[i].qty) {
                            return ElMessage({
                                type: "warning",
                                message: "请填写销售价格、销售折扣、数量",
                            })
                        }
                        if (data[i].shipArea) {
                            data[i].shipArea = data[i].shipArea.join(",");
                        }
                        data[i].manufactureDate = moment(data[i].manufactureDate).format(
                            "YYYY-MM-DD"
                        );
                        data[i].skuId = data[i].skuId;
                    }
                    //提交
                    this.quoteSetSkuCreate({
                        setSkuList: data,
                    }).then((res) => {
                        let {code, data} = res.data;
                        if ((code === "0" && !data) || (data && data.code === "0")) {
                            ElMessage({
                                type: "success",
                                message: "添加成功",
                            });
                            this.goods = []; //清空数据
                            this.$emit("success"); //添加成功回调
                            this.close();
                        } else {
                            ElMessage({
                                type: "warning",
                                message: data.mag,
                            });
                        }
                    });
                } else {
                    ElMessage({
                        type: "warning",
                        message: "请选择要添加的商品",
                    });
                }
            },
            inputLv(row, type, linkage) {
                //监听面价点数和利润点数输入的事件
                row.numType = "qty";
                row = offerProfitCompute(row, type, linkage);
            },
            edit() {
                this.sectionData.forEach(v => {
                    if (!this.formLabelAlign.salesDis && !this.formLabelAlign.qty) {
                        ElMessage({
                            type: "error",
                            message: '请填写销售折扣和数量',
                        });
                        return false
                    }
                    // v.guidePrice=this.formLabelAlign.guidePrice
                    if (this.formLabelAlign.purchaseDis) {
                        v.purchaseDis = this.formLabelAlign.purchaseDis
                    }
                    if (this.formLabelAlign.salesDis) {
                        v.salesDis = this.formLabelAlign.salesDis
                    }
                    // v.purchasePrice=this.formLabelAlign.purchasePrice
                    // v.salesPrice=this.formLabelAlign.salesPrice
                    if (this.formLabelAlign.qty) {
                        v.qty = this.formLabelAlign.qty
                    }
                    if (this.formLabelAlign.manufactureDate) {
                        v.manufactureDate = this.formLabelAlign.manufactureDate
                    }
                    if (this.formLabelAlign.channel) {
                        v.channel = this.formLabelAlign.channel
                    }
                    if (this.formLabelAlign.shipArea) {
                        v.shipArea = this.formLabelAlign.shipArea
                    }
                });
                this.$nextTick(() => {
                    this.sectionData.forEach(v => {
                        this.inputLv(v, 'salesDis')
                    })
                })
                this.visible = false
            },
            selectionChange(val) {
                this.sectionData = val
            },
        },
    };
</script>

<style scoped lang="less">
    .a {
        padding: 20px;

        .a-title {
            padding-bottom: 20px;
            font-weight: bold;
        }

        .a-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
        }
    }

    .p {
        .p-top {
            display: flex;
            align-items: center;
        }
    }

    .t-row {
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
        }
    }

    .t-profit {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        .t-profit-i {
            display: flex;
            padding-bottom: 5px;

            span {
                width: 100px;
                color: #999999;
            }
        }
    }
</style>
