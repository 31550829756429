import {
	mapState,
	mapActions,
	mapMutations,
} from 'vuex'
import {
	getLetterSortData, //按字母排序
	handleTreeData, //处理树形数据
	handleZIPflow, //处理压缩文件流数据
	checkType,
	imgUrl,  //处理图片
} from "@/common/js/common.js"

export default {
	data() {
		return {
			ruleForm: {
				officialWebsite:'',
				brandId: null, // 厂家id
				seriesId: null, // 系列id
				type: 'SUBJECT', // 类型 SUBJECT本体,ACCESSORY附件
			},
			brandList: [], //品牌列表
			seriesList: [], //系列列表
			subjects: [], //本体规格
			relationAccessory: [], //关联附件
			productList: [], //商品id列表
			commonAccessory: [], //常用附件
			accessory: [], //附件
			goodsList: [], //商品列表
			selectSkuSpecIds: [], //当前选中的规格列表
			productInfo: {}, //商品详情
			accessoryProductList: [], //附件商品
			navs: [
				{title: '本体规格',name: 'SUBJECT'},
				{title: '选择附件',name: 'R_ACCESSORY'},
			],
			// 查询列表数据
			queryGoodsData: {
				pageNo: 1, // 第几页
				pageSize: 200, // 每页显示的条数
				totalCount: 0 // 总条数
			},
		}
	},
	methods: {
		...mapActions('purchaseMudule',
			[
				'listSkuCategoryForFirst', //顶级分类请求
				'listSkuCategoryForChildren', //获取子集分类
				'jsonV1', //获取页面数据
				'skuPage', //获取商品列表
			]),
		init() { //页面初始化
			this.getBrandList();
		},
		getBrandList() { //获取品牌列表
			this.listSkuCategoryForFirst({
				pageNo: 1,
				pageSize: 99999
			}).then(res => {
				let {
					data,
					code
				} = res.data.data;
				if (code === "0" && data.length) {
					data = getLetterSortData(data, 'initialCode');
					data.map((item, index) => {
						item.id = 'a_' + (index + 1);
						item.label = item.letter;
						if (item.children) {
							item.children.map(son => {
								son.label = son.categoryName;
								delete son.children;
							})
						}
					})
					//默认选中第一个
					this.brandList = data;
					this.ruleForm.brandId = data[0].children[0].id;
					this.ruleForm.officialWebsite = data[0].children[0].officialWebsite;
					//根据厂家id获取系列
					this.getseriesList22('ruleForm',true); //获取当前系列
				}
			})
		},
		recursion(children){
			if(children.hasChildren) {
				if(children.children[0].hasChildren){
					this.recursion(children.children[0].children[0]);
				}else{
					this.ruleForm.seriesId=children.children[0].id
				}
			}else{
				this.ruleForm.seriesId=children.id
			}
		},
		getseriesList22(key, init) {
			// 获取系列列表
			if (this.ruleForm.brandId) {
				this.listSkuCategoryForChildren({
					parentId: this[key].brandId,
				}).then((res) => {
					let {data, code} = res.data.data;
					if (code === "0" && data.length) {
						if (init) {
							this.seriesList2 = handleTreeData(data, "categoryName", 'id');
							this.recursion(this.seriesList2[0])
							this.getData()
							// this.getCustomerData();
						} else {
							const listKey = key === "ruleForm" ? "seriesList2" : "seriesList22";
							this[listKey] = handleTreeData(data, "categoryName", 'id');
							// if (key === "ruleForm") {
							//   this.getSupplierData();
							// } else {
							//   this.getCustomerData();
							// }
						}
					}
				});
			}
		},
		getSeriesList(id,idxs) { //获取系列列表
			this.listSkuCategoryForChildren({
				parentId: this.ruleForm.brandId,
			}).then(res => {
				let {
					data,
					code
				} = res.data.data;
				if (code === "0" && data.length) {
					this.seriesList = handleTreeData(data, 'categoryName');
					this.ruleForm.seriesId = id || this.getOneId(this.seriesList);
					this.getData(idxs); //获取页面数据
				}
			})
		},
		//获取第一个id
		getOneId(data, text) {
			if (data.length && data[0].children && data[0].children.length) {
				return this.getOneId(data[0].children);
			} else {
				return data[0].id;
			}
		},
		getData(idxs) { //获取页面数据
			this.jsonV1(
				this.ruleForm.brandId + '/' + this.ruleForm.seriesId
			).then(res => {
				handleZIPflow(res.data, "search.json").then(data => {

					data.commonAccessory.forEach((item) => {
						item.specValueId = "";
					})
					this.subjects = data.subjects; //本体规格
					this.relationAccessory = data.relationAccessory; //关联附件
					this.productList = data.productList; //商品列表
					this.commonAccessory = data.commonAccessory; //常用附件
					this.accessory = data.accessory; //附件
					this.allSkuSpecIdxs = []; //所有商品的规格id拼接值
					this.productList.map((i) => {
						if (i.skuSpecIdx) {
							this.allSkuSpecIdxs.push(i.skuSpecIdx);
						}
					})

					if (this.allSkuSpecIdxs.length || typeof idxs !== 'undefined') {
						if(typeof idxs === 'undefined'){
							// 默认展示第一个商品
							this.selectSkuSpecIds = this.allSkuSpecIdxs[0].split('-')
							//根据第一个规格值去获取商品
							this.getGoodsList();
						}else{
							this.selectSkuSpecIds = idxs ? idxs.split('-') : [];
						}
					} else {
						// 该系列下没有商品 清空商品数据
						this.goodsList = []
						this.productList = []
						this.accessoryProductList = []
					}
				})
			})
		},
		//获取包含当前规格的商品的数组
		getSpecIdsGoods(ids, goods, key) {
			var arr = [];
			goods.map(item => {
				if (this.judgeIsContainNumber(ids, key ? item[key].split('-') : item.split('-'))) {
					arr.push(item);
				}
			})
			return arr;
		},

		//判断是否数组中有另一个数组的所有值(b里面是否包含a)
		judgeIsContainNumber(a, b) {
			var k = 0;
			a.map(item => {
				if (b.some(i => i === item)) {
					k++;
				}
			})
			return k === a.length;
		},

		// 去重
		unique(arr) {
			return [...new Set(arr)]
		},

		//获取当前选择规格所包含的常用附件的id
		getCommSpecValues() {
			var selectSkuSpecIds = []; //当前选中的规格值（筛选常用附件规格后的）
			this.subjects.map(item => {
				item.specValueResultList.map(i => {
					if (this.selectSkuSpecIds.some(v => v === i.id)) {
						selectSkuSpecIds.push(i.id)
					}
				})
			});
			var data = JSON.parse(JSON.stringify(this.commonAccessory));
			data.map(item => {
				item.specValueResultList.map(son => {
					son.isShow = this.judgeIsContainNumber(
						selectSkuSpecIds,
						son.specValueIds ? son.specValueIds.split(',') : []
					);
				})
				item.isShow = item.specValueResultList.some(v => v.isShow);
			})
			return data;
		},

		getGoodsList(clone) { //获取商品列表
			var val = JSON.parse(JSON.stringify(this.selectSkuSpecIds));
			var goodsList = JSON.parse(JSON.stringify(this.goodsList));
			const skuSpecIdx = val.join('-')
			// 查找对应的规格id的商品
			let hasSkuSpecIdx = this.productList.find(
				(i) => this.judgeArrEqual(i.skuSpecIdx.split('-'),skuSpecIdx.split('-'))
			)
			if (hasSkuSpecIdx) {
				var idx;
				if (clone) {
					idx = goodsList.findIndex(
						(i) => this.judgeIsContainNumber(clone, i.skuSpecIdx.split('-'))
					)
				}
				// 获取商品列表
				this.skuPage({
					...this.queryGoodsData,
					articleNos: [hasSkuSpecIdx.articleNo],
				}).then((res) => {
					let {
						code,
						data
					} = res.data
					if (code === '0') {
						let {
							rows
						} = data
						// 筛选相同skuSpecIdx的数据
						let list = rows.filter((i) => i.skuSpecIdx === skuSpecIdx)
						if (list && list.length) {

							list.map(item => {
								item.num = 1
								item.src = imgUrl(item);
								// 替换商品窗口数据
								if (item.skuReplaceVOList && item.skuReplaceVOList.length > 0) {
									for (const val of item.skuReplaceVOList) {
										val.num = 1;
										if (val.files) {
											const files = JSON.parse(val.files)
											val.filesUrl = files[0] ? files[0].url : ''
										}
									}
								}
							});
							console.log(list, '----list')

							/**
							 * 默认展示第一个产品的信息
							 */
							let firstProduct = list[0]
							this.productInfo = {
								...firstProduct,
								src: imgUrl(firstProduct),
								detailsFiles: JSON.parse(firstProduct.detailsFiles || '[]')
							}
							if (typeof idx === "number" && idx !== -1) {
								goodsList[idx] = list[0];
							} else {
								goodsList = list
							}
						}
						this.goodsList = [...goodsList];
					}
				})
			} else {
				this.goodsList = [] // 清空商品数据
			}
		},
		specChange(specItem, son, index) { //规格改变的事件
			let {
				type,
				specValueResultList,
				id
			} = specItem,
			specValueId = son.id;
			this.selectSkuSpecIds = this.setIdsSort(this.selectSkuSpecIds);
			if (type === 'SUBJECT') {
				var k,
					cloenSpec = JSON.parse(JSON.stringify(this.selectSkuSpecIds)), //保存更新前的规格值（好根据规格值匹配商品）
					specIdx = [];
				specValueResultList.map(val => {
					this.selectSkuSpecIds.map((id, i) => {
						if (id === val.id) {
							k = i;
						}
					})
				})
				this.selectSkuSpecIds.map((item, index) => {
					if (typeof k === "number") {
						if (index < k) {
							specIdx.push(item);
						}
					} else {
						specIdx.push(item);
					}
				})
				specIdx.push(son.id);
				//筛选出包含当前规格的商品
				var goods = this.getSpecIdsGoods(specIdx, this.allSkuSpecIdxs);
				if (typeof k === 'number') {
					if (goods.length) { //判断是否匹配到商品
						this.selectSkuSpecIds = goods[0].split('-');
					} else { //如果没有匹配到按原来选中的继续
						this.selectSkuSpecIds[k] = specValueId;
					}
				} else {
					if (goods.length) {
						this.selectSkuSpecIds = goods[0].split('-');
					} else {
						this.selectSkuSpecIds.push(specValueId);
					}
				}
				//获取商品列表
				this.getGoodsList(cloenSpec);
			} else if (type === "C_ACCESSORY") {
				var goodsList = JSON.parse(JSON.stringify(this.goodsList));
				// 常用附件
				/**
				 * 判断当前规格值有没有必选附件ids(accessorySelectIds)
				 * 有必选附件 就根据厂家和系列搜索的数据 判断有没有该id
				 * 有id之后根据货号查询商品
				 */
				// 必选附件集合
				let accessorySelectIds = (
					specValueResultList.find(
						(i) => i.id === (specValueId || son.cancelId)
					) || {}
				).accessorySelectIds

				this.commonAccessory.map(item => {
					if (item.id === id) {
						item.specValueId = son.cancelId ? "" : son.id
					}
				})

				if (son.cancelId) {
					// 先删除该规格下的所有商品
					let list = goodsList.filter((i) => i.specId === id)
					list.forEach((item) => {
						goodsList.splice(
							goodsList.findIndex((i) => i.id === item.id),
							1
						)
					})
				}
				this.$forceUpdate()
				if (specValueId) {
					// 先删除该规格下的所有商品
					let list = goodsList.filter((i) => i.specId === id)
					list.forEach((item) => {
						goodsList.splice(
							goodsList.findIndex((i) => i.id === item.id),
							1
						)
					})
					if (!accessorySelectIds) return
					const selectGoodsIds = accessorySelectIds.split(',')
					/**
					 *根据该附件选择的商品id查询商品
					 */
					this.skuPage({
						ids: selectGoodsIds
					}).then((res) => {
						let {
							code,
							data
						} = res.data
						if (code === '0') {
							let {
								rows
							} = data
							rows.forEach((items) => {
								items.specId = id
								items.specValueId = specValueId;
								items.num = 1;
								items.src = imgUrl(items);
								// 替换商品窗口数据
								if (item.skuReplaceVOList && item.skuReplaceVOList.length > 0) {
									for (const val of item.skuReplaceVOList) {
										val.num = 1;
										if (val.files) {
											const files = JSON.parse(val.files)
											val.filesUrl = files[0] ? files[0].url : ''
										}
									}
								}
							})
							if (rows.length) {
								goodsList.push(rows[0])
							}
							this.goodsList = [...goodsList];
						}
					})
				}
			}
		},
		// 附件选择规格变化
		accessorySpecChange(specItem, specValueItem) {
			if (specValueItem && specValueItem.accessorySelectIds) {
				let selectGoodsIds = specValueItem.accessorySelectIds.split(',')
				/**
				 *根据该附件选择的商品id查询商品
				 */
				this.skuPage({
					ids: selectGoodsIds
				}).then((res) => {
					let {
						code,
						data
					} = res.data
					if (code === '0') {
						let {
							rows
						} = data
						this.accessoryProductList = rows
						if (rows && rows.length) {
							let specValueIds = rows[0].skuSpecIdx.split('-')
							this.getAccessoryData(specValueItem, specValueIds)
						}
					}
				})
			}
		},
		// 关联附件选中的规格
		accessorySpecValueChange(specValueItem, it) {
			var specIdx = [],
				idx;
			let accessoryList = JSON.parse(JSON.stringify(this.accessory))
			accessoryList.map((item, index) => {
				if (item.id === it.id) {
					idx = index;
				}
			})
			accessoryList.map((item, index) => {
				if (index <= idx) {
					specValueItem.specList.map(son => {
						if (son.id === item.id) {
							item.selectId = son.selectId;
						}
					})
					specIdx.push(item.selectId);
				}
			})
			var goods = this.getSpecIdsGoods(specIdx, this.accessoryProductList, 'skuSpecIdx'),
				ids = goods.length ? goods[0].skuSpecIdx.split('-') : [];
			accessoryList.map((item, index) => {
				if (index > idx) {
					var id = "";
					item.selectId = item.specValueResultList.some(i => {
						id = "";
						ids.map(g => {
							if (i.id === g) {
								id = g;
							}
						})
						return id;
					}) ? id : "";
				}
			})
			this.getAccessoryData(
				specValueItem,
				accessoryList.map((i) => i.selectId).filter(i => i),
			)
		},
		// 获取附件规格
		getAccessoryData(specValueItem, specValueIds) {
			let skuSpecIds = JSON.parse(JSON.stringify(specValueIds))
			let accessoryList = JSON.parse(JSON.stringify(this.accessory))
			accessoryList.forEach((item) => {
				specValueIds.forEach((value, i) => {
					/**
					 * 设置选中的规格值id
					 */
					if (item.specValueResultList.some((i) => i.id === value)) {
						item.selectId = value
						item.skuSpecIdsIndex = i
						/**
						 * 显示有商品的规格值
						 */
						const sliceIds = skuSpecIds.slice(0, i)
						const ids = this.getHasGoodsSpecValueId(
							i,
							sliceIds.join('-'),
							this.accessoryProductList.map((i) => i.skuSpecIdx)
						) // 筛选有商品的规格值id集合
						item.specValueResultList.forEach((items) => {
							items.isShow = ids.some((v) => v === items.id)
						})
					}
				})
			})
			specValueItem.specList = accessoryList.filter((i) => i.selectId)
			specValueItem.infoShow = true;
			//获取附件商品数据
			this.getAccessoryGoodsData(specValueItem.specList, specValueItem.id)
		},
		// 获取附件商品数据
		getAccessoryGoodsData(data, specValueId) {

			let skuSpecIdx = data.map((i) => i.selectId).join('-')
			let goods = this.accessoryProductList.find(
				(i) => i.skuSpecIdx.indexOf(skuSpecIdx) !== -1
			)
			var goodsList = JSON.parse(JSON.stringify(this.goodsList));
			const index = goodsList.findIndex(
				(i) => i.specValueId === specValueId
			)
			if (goods) {
				goods.src = imgUrl(goods);
				/**
				 * 判断商品列表有没有该规格值的商品
				 * 没有就加
				 * 有就替换
				 */
				let hasGoods = goodsList.some(
					(i) => i.specValueId === specValueId
				)
				if (!hasGoods) {
					goodsList.push({
						...goods,
						num: 1,
						specValueId: specValueId
					})
				} else {
					goodsList[index] = {
						...goods,
						num: 1,
						specValueId: specValueId
					}
				}
			} else {
				if (typeof index === "number" && index !== -1) {
					goodsList.splice(index, 1);
				}
			}
			this.goodsList = [...goodsList];
		},
		/**
		 * 获取当前规格有有商品的规格值id
		 */
		getHasGoodsSpecValueId(index, someVulue, arr){
			let list = []
			if (index === 0) {
				list = arr
			} else {
				// 根据规格值id查找相应的数据
				let a = someVulue.split('-')
				arr.forEach((item) => {
					let ids = item.split('-')
					let b = ids.slice(0, a.length)
					if (someVulue === b.join('-')) {
						list.push(item)
					}
				})
			}
			// 根据索引获取所有skuSpecIdx的id
			// 列如：skuSpecIdx： '4-10-65-18-19-27-38-54-68-76-' index = 2 获取的就是 10
			let ids = list.map((item) => {
				let ids = item.split('-')
				return ids.length ? ids[index] : 0
			})
			// 得到去重后的数据
			return this.unique(ids)
		},
		clearData() { //清空数据
			this.subjects = []; //本体规格
			this.relationAccessory = []; //关联附件
			this.productList = []; //商品id列表
			this.commonAccessory = []; //常用附件
			this.accessory = []; //附件
			this.goodsList = []; //商品列表
			this.selectSkuSpecIds = []; //当前选中的规格列表
			this.productInfo = {}; //商品详情
			this.accessoryProductList = []; //附件商品
		},
		//获取当前选中的系列信息
		getSelectNode(data, id, row) {
			row = row || null;
			data.map(item => {
				if (item.id === id) {
					row = item;
				} else {
					if (item.children && item.children.length && !row) {
						row = this.getSelectNode(item.children, id, row);
					}
				}
			})
			return row;
		},
		//重新排序
		setIdsSort(ids){
			ids = JSON.parse(JSON.stringify(ids));
			var arr = [];
			this.subjects.map(item => {
				var index = item.specValueResultList.findIndex(v => ids.some(k => k === v.id));
				if(index !== -1){
					arr.push(item.specValueResultList[index].id);
				}
			})
			ids.map(item => {
				if(!arr.some(v => v === item)){
					arr.push(item);
				}
			})
			return arr;
		},
		judgeArrEqual(a,b){  //判断两个数组值是否一样
			var k = 0;
			a.map(item => {
				if(b.some(v => v === item)){
					k++;
				}
			})
			return k === a.length && a.length === b.length;
		},
	},
	computed: {
		// 规格数据
		specListData() {
			let data = []
			if (this.ruleForm.type === 'SUBJECT') { //本体规格
				data = [...this.subjects]; //本体规格
				var skuSpecIds = JSON.parse(JSON.stringify(this.selectSkuSpecIds)); //当前选中的规格id
				skuSpecIds = this.setIdsSort(skuSpecIds);
				data.forEach((item, k) => {
					/**
					 * 回显选中的规格
					 */
					var key = skuSpecIds.slice(0, k);
					var goods = this.getSpecIdsGoods(key, this.allSkuSpecIdxs);
					var ids = [];
					//获取当前显示的规格数组
					goods.map(g => {
						ids = [...ids, ...g.split('-')]
					})
					//去重
					ids = this.unique(ids);
					item.specValueResultList.forEach((items) => {
						items.isShow = ids.some(v => v === items.id);
						if (items.isShow && this.selectSkuSpecIds.some(i => i === items.id)) {
							item.specValueId = items.id;
						}
					})
					item.isShow = !!item.specValueResultList.filter((i) => i.isShow).length
				})
				this.commonAccessory = this.getCommSpecValues();
				data = [
					...data,
					...this.getCommSpecValues(),
				]
			} else if (this.ruleForm.type === 'R_ACCESSORY') { //附件规格
				let selectCommonAccessory = []
				/**
				 * 选中的常用附件有必选附件产品 就回显到tab为附件那边
				 */
				this.commonAccessory.forEach((item) => {
					if (item.specValueId) {
						let specValueObj = item.specValueResultList.find(
							(i) => i.id === item.specValueId
						)
						if (specValueObj && specValueObj.accessorySelectIds) {
							selectCommonAccessory.push({
								...item,
								specValueIds: [specValueObj.id],
								specValueResultList: [specValueObj]
							})
						}
					}
				})
				data = [...selectCommonAccessory, ...this.relationAccessory]
				/**
				 * infoShow 当前选中的附件规格信息 显示
				 * specValueIds 选中的规格值id集合
				 */
				data.forEach((item) => {
					/**
					 * 回显选中的规格
					 * 默认第一个商品为附件商品时
					 */
					this.selectSkuSpecIds.forEach((value) => {
						if (item.specValueResultList.some((i) => i.id === value)) {
							item.specValueIds = [value]
						}
					})
					/**
					 * 筛选有商品的规格值
					 */
					item.specValueResultList.forEach((items) => {
						if (
							item.type === 'R_ACCESSORY' &&
							(items.accessorySelectIds || items.accessoryIds)
						) {
							items.isShow = true
						}
						/**
						 * 选中的常用附件有商品 就回显
						 */
						if (item.type === 'C_ACCESSORY') {
							items.isCheck = true;
							items.isDisabled = true;
						}
					})
					item.isShow = !!item.specValueResultList.filter((i) => i.isShow).length
				})
			}
			return data
		},
		categoryNumber() {
			var row;
			if (this.ruleForm.seriesId) {
				row = this.getSelectNode(this.seriesList, this.ruleForm.seriesId);
			}
			return row ? row.categoryNumber : null;
		},

	},
}
