<template>
  <el-dialog width="1300px" center :show-close="false">
    <div class="a">
      <div class="a-title">导入在售商品</div>
      <div style="padding: 25px;">
        <el-steps :active="active" finish-status="success">
          <el-step title="导入文件" />
          <el-step title="预览" />
          <el-step title="导入完成" />
        </el-steps>

        <div style="padding: 25px;" v-show="active === 0">
          <div class="" style="margin: 15px 0;">
            <el-button
              style="margin-right: 20px;"
              size="mini"
              @click="exportOrder"
              >下载模板
            </el-button>
            <span style="font-size: 12px; color: gray; "
              >如有模板请忽略这一步</span
            >
          </div>
          <el-divider />
          <div style="margin:15px 0;">
            <div style="margin:15px 0;">上传文件：</div>
            <el-upload
              class="upload-demo"
              accept=".xlsx"
              ref="uploadRef"
              :on-remove="() => (fileList = [])"
              :action="getAction"
              :headers="getHeaders"
              :on-success="uploadSuccess"
              :limit="1"
            >
              <template #default>
                <el-button size="mini">
                  {{
                    fileList.length
                      ? "请删除文件后重新上传"
                      : "选择xlsx格式文件上传"
                  }}
                </el-button>
              </template>
            </el-upload>
          </div>

          <div style="margin:15px 0;">
            <div style="margin:15px 0;" class="">选择品牌：</div>

            <el-select
              style="width: 320px; "
              placeholder="请选择品牌"
              v-model="brandId"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>

        <div style="padding: 25px;" v-if="active === 1">
          <div style=" margin: 15px 0;">
            文件名：
            <span style="font-weight: bold;">{{ fileList[0].fileName }} </span>
          </div>
          <el-table :data="fileList[0].importData" height="300">
            <el-table-column type="index" width="55" label="序号" />
            <el-table-column property="productName" label=" 产品 " />
            <el-table-column property="articleNo" label="订货号" />
            <el-table-column property="barCode" label="型号" />
            <el-table-column property="qty" label="数量" />

            <el-table-column property="salePrice" label="销售价" />
          </el-table>
        </div>

        <div style="padding: 25px;text-align: center;" v-show="active === 2">
          <div v-if="importDataRes">
            <div class="" v-if="failName">
              <div style="margin: 20px; font-weight: bold; color:#d84146 ;">
                导入失败{{ importDataRes.failQty || 0 }}个！请下载导入失败列表
              </div>
              <el-button
                style="margin-right: 20px;"
                size="mini"
                @click="dFailList"
                >下载
              </el-button>
            </div>

            <div
              class=""
              v-if="importDataRes.successQty > 0"
              style="line-height: 2; margin-top: 15px; font-weight: bold;"
            >
              <div>
                将要导入<span style="color: cornflowerblue;">
                  {{ importDataRes.successQty || 0 }}
                </span>
                个
              </div>
              <div>正在导入,数量：{{ QuoteSetSku }}个</div>
              <div>
                导入成功,数量：<span style="color:cornflowerblue;">
                  {{ importDataRes.successQty - QuoteSetSku }}
                </span>
                个
              </div>
            </div>
          </div>

          <div v-else style="font-weight: bold;">操作失败！</div>
        </div>
      </div>

      <div class="a-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close"
          >取消
        </el-button>
        <el-button
          v-if="active !== 0"
          style="margin: 0px 20px;"
          type="primary"
          size="mini"
          @click="() => active--"
          >上一步
        </el-button>
        <el-button
          v-if="active !== 2"
          style="margin-left: 20px;"
          type="primary"
          size="mini"
          @click="confirm"
          >下一步
        </el-button>
        <el-button
          v-if="active == 2"
          style="margin-left: 20px;"
          type="primary"
          size="mini"
          @click="
            () => {
              $emit('search');
              close();
            }
          "
          >完成
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";
import { handleTreeData, getLetterSortData } from "@/common/js/common.js";
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import moment from "moment";
import area from "@/common/json/area.json";
import request from "@/common/js/request.js";
import { downloadExcel } from "@/common/js/downloadExcel.js";
import api from "@/common/js/api.js";
export default {
  components: {},
  data() {
    return {
      active: 0,
      fileList: [],
      failName: "", //导入失败的文件
      importDataRes: null,
      QuoteSetSku: 0,
      timer: null,
      brandId: undefined,
      options:[]
    };
  },
  props: {},
  computed: {
    ...mapState([
      "token", //获取token
    ]),

    getAction() {
      //上传文件地址
      return (
        request.defaults.baseURL + "/quote/set/import/quoteSetSku/importFile"
      );
    },
    getHeaders() {
      //请求头部
      return {
        Authorization: this.token || null,
      };
    },
  },

  created() {

    this.listSkuCategoryForFirst({
    pageNo: 1,
    pageSize: 15,
  }).then((res) => {
    let { data, code } = res.data.data;

    if (code === "0" && data.length) {
      this.options = data.map((item, index) => {
            item.label = item.categoryName;
            item.value = item.id;
        return item;
      });


    }
  });
    // this.getBrandList();
  },
  methods: {
    ...mapActions("purchaseMudule", ["listSkuCategoryForFirst"]),

    init() {
      this.brandId = undefined;
      this.$refs.uploadRef.clearFiles();
      clearInterval(this.timer);
      this.active = 0;
      this.failName = "";
      this.fileList = [];
      this.importDataRes = null;
      this.QuoteSetSku = 0;
    },
    exportOrder() {
      return request
        .get("/quote/set/import/template/download/quoteSetSku")
        .then((res) => {
          if (res.data?.data) {

            window.open(`${process.env.NODE_ENV === 'development'||process.env.NODE_ENV === 'test'?'https://test.lidacn.net/mall/lidamall':'https://www.panlei.cn/lidamall'}/excel/${res.data.data.data}`);
          }
        });
    },

    dFailList() {
      if (this.failName) {
        window.open(`https://www.panlei.cn/lidamall/excel/${this.failName}`)
      }
    },
    uploadSuccess(res) {
      if (res.data.data) {
        this.fileList = [res.data.data];
      }
    },
    //
    close() {
      //关闭弹框
      this.$emit("update:modelValue", false);

      this.init();
    },

    confirm() {
      if (this.active === 0) {
        if (!this.fileList.length) {
          return ElMessage({
            type: "warning",
            message: "请上传文件",
          });
        }
        if (!this.brandId) {
          return ElMessage({
            type: "warning",
            message: "请选择品牌",
          });
        }
        this.active = 1;
      } else if (this.active === 1 ) {
        request
          .get(`/quote/set/import/quoteSetSku/importDb`, {
            params: {
              fileName: this.fileList[0].fileName,
              brandId: this.brandId,

            },
          })
          .then((res) => {
            if (res.data.data?.data) {
              if (res.data.data.data.failQty) {
                this.failName = res.data.data.data.failFileName;
              }

              this.importDataRes = res.data.data.data;

              if (this.importDataRes.successQty > 0) {
                this.timer = setInterval(() => {
                  request.get(`/quote/set/import/importSchedule`).then((ex) => {
                    this.QuoteSetSku = ex.data.data?.data?.QuoteSetSku || 0;
                    if (this.QuoteSetSku === 0) {
                      clearInterval(this.timer);
                    }
                  });
                }, 1000);
              }
            }

            this.active = 2;
          });
      }
    },
  },
};
</script>
<!-- <script setup>
import {ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
const store = useStore();

let options = ref([]);
const listSkuCategoryForFirst = () =>
  store.dispatch("purchaseMudule/listSkuCategoryForFirst", {
    pageNo: 1,
    pageSize: 99999,
  });
onMounted(() => {
  listSkuCategoryForFirst().then((res) => {
    let { data, code } = res.data.data;

    if (code === "0" && data.length) {
      options.value = data.map((item, index) => {
            item.label = item.categoryName;
            item.value = item.id;
        return item;
      });

      console.log(options.value);
    }
  });
});


</script> -->

<style scoped lang="less">
.a {
  padding: 20px;

  .a-title {
    padding-bottom: 20px;
    font-weight: bold;
  }

  .a-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
}

.p {
  .p-top {
    display: flex;
    align-items: center;
  }
}

.t-row {
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}

.t-profit {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .t-profit-i {
    display: flex;
    padding-bottom: 5px;

    span {
      width: 100px;
      color: #999999;
    }
  }
}
</style>
