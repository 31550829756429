<template>
    <div>
      <div class="c">
        <div class="c-top" style="padding-bottom: 10px;display: flex;margin-top: 20px;">
          <!-- <div style="display:flex">
            <tree-select
              style="width:220px;marginRight:20px"
              placeholder="品牌"
              v-model="filter2.brandId"
              :clearable="true"
              :options="brandList"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              :key="filter2.brandId"
              @select="(node) => treeChange2(node, 'brandId')"
              :flatten-search-results="true"
            />
            <tree-select
              style="width:250px;marginRight:20px"
              placeholder="系列"
              :clearable="true"
              :options="seriesList2"
              v-model="filter2.seriesId"
              :key="filter2.seriesId"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              @select="(node) => treeChange2(node, 'seriesId')"
              :flatten-search-results="true"
            />
  
          </div> -->
          <div style="marginRight:20px;width: 200px;">
            <el-select
              v-model="filter2.brandId"
              placeholder="请选择品牌"
              @change="optionsChange"
            >
              <el-option label="所有品牌" value=""></el-option>
              <el-option
                v-for="item in brandList"
                :key="item.brandId"
                :label="item.brandName"
                :value="item.brandId"
              >
              </el-option>
            </el-select>
          </div>
          <div style="marginRight:20px;">
            <!--          <tree-select-->
            <!--            style="width:250px;marginRight:20px"-->
            <!--            placeholder="系列"-->
            <!--            :clearable="true"-->
            <!--            :options="seriesList2"-->
            <!--            v-model="filter2.seriesId"-->
            <!--            :key="filter2.seriesId"-->
            <!--            :disable-branch-nodes="true"-->
            <!--            :searchable="true"-->
            <!--            :defaultExpandLevel="10"-->
            <!--            @select="(node) => treeChange2(node, 'seriesId')"-->
            <!--            :flatten-search-results="true"-->
            <!--          />-->
            <el-cascader
              :options="seriesList"
              placeholder="系列"
              :show-all-levels="false"
              style="position: relative;top:-5px;"
              filterable
              clearable
              v-model="filter2.seriesIdTemp"
              @change="seriesChange"
            />
          </div>
          <div style="margin-right:20px;">
            <el-date-picker
              style="width:400px;margin-right:20px"
              class="date-picker-box"
              v-model="filter2.queryDate"
              type="daterange"
              :clearable="true"
              unlink-panels
              append-to-body="true"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDate2"
              :shortcuts="shortcuts"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD HH:mm:ss"
            ></el-date-picker>
          </div>
          <div style="display:flex;margin-top:-5px">
            <el-input
              v-model.trim="keyword"
              placeholder="请输入商品名称、厂家、系列、型号、订货号..."
              style="width:300px;margin-right: 20px;"
            ></el-input>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="search">搜索</el-button>
          </div>
        </div>
        <lida-table
          :cutHeight="0"
          :data="customerData"
          border
          :page="page"
          :pageSize="pageSize"
          :total="total"
          :pagination="true"
          ref="table"
          @change="pageChange"
        >
          <el-table-column type="index" label="序号" width="55" />
          <lida-table-column label="求购时间" prop="createTime" />
          <lida-table-column label="商品名称" prop="productName" />
          <lida-table-column label="公司名称"  >
            <template v-slot="scope">
              <div>
                {{ scope.row.enterpriseName || "" }}
                <div style="margin:10px 0;">
                  <el-tag
                    v-if="scope.row.isAgent"
                    size="mini"
                    style="margin-right:10px"
                  >
                    {{ scope.row.isAgent ? "代理" : "" }}
                  </el-tag>
                  <el-tag
                    v-if="scope.row.proprietaryFlag === '1'"
                    type="success"
                    size="mini"
                    style="margin-right:10px"
                  >
                    {{ scope.row.proprietaryFlag === "1" ? "自营" : "" }}
                  </el-tag>
                  <el-tag
                    v-if="scope.row.authFlag === '1'"
                    type="warning"
                    size="mini"
                    style="margin-right:10px"
                    >{{ scope.row.authFlag === "1" ? "认证" : "" }}
                  </el-tag>
                  <el-tag
                    v-if="scope.row.manufactorFlag === '1'"
                    type="warning"
                    size="mini"
                    style="margin-right:10px"
                    >{{ scope.row.manufactorFlag === "1" ? "厂家" : "" }}
                  </el-tag>
                </div>
              </div>
            </template>
          </lida-table-column>
  
          <lida-table-column label="品牌" prop="brandName" />
          <lida-table-column label="系列" prop="seriesName" />
          <lida-table-column label="订货号" prop="articleNo" />
          <lida-table-column label="型号" prop="barCode" />
          <lida-table-column label="数量" prop="qty" width="100" />
          <lida-table-column label="操作" width="180">
            <template #default="scope">
              <!-- <div @click="deleteRow(scope.row)" class="row-delete">删除</div> -->
              <el-button @click="changeRow(scope.row)" type="primary"
                >报价
              </el-button>
            </template>
          </lida-table-column>
        </lida-table>
      </div>
    </div>
  </template>
  
  <script>
  import {
    handleTreeData, //处理树形数据
  } from "@/common/js/common.js";
  import { mapActions } from "vuex";
  import moment from "moment";
  moment.locale("zh-cn");
  
  export default {
    name: "supplierPage",
    props: {
      userInfo: {
        type: Object,
      },
    },
    data() {
      return {
        currentType: "QUOTE",
        supplierData: [],
        customerData: [],
        filter2: {
          brandId: "",
          seriesId: null,
          seriesIdTemp: null,
          queryDate: [
            moment()
              .subtract(1, "month")
              .format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59"),
          ],
        },
        total: 0,
        page: 1,
        pageSize: 15,
        shortcuts: [
          {
            text: "今日",
            value: () => {
              const start = moment().format("YYYY-MM-DD 00:00:00");
              const end = moment().format("YYYY-MM-DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "昨日",
            value: () => {
              const start = moment()
                .subtract(1, "days")
                .format("YYYY-MM-DD 00:00:00");
              const end = moment()
                .subtract(1, "days")
                .format("YYYY-MM-DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "本周",
            value: () => {
              const start = moment(
                moment()
                  .week(moment().week())
                  .startOf("week")
                  .valueOf()
              ).format("YYYY/MM/DD 00:00:00");
              const end = moment(
                moment()
                  .week(moment().week())
                  .endOf("week")
                  .valueOf()
              ).format("YYYY/MM/DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "上周",
            value: () => {
              const start = moment(
                moment()
                  .week(moment().week() - 1)
                  .startOf("week")
                  .valueOf()
              ).format("YYYY/MM/DD 00:00:00");
              const end = moment(
                moment()
                  .week(moment().week() - 1)
                  .endOf("week")
                  .valueOf()
              ).format("YYYY/MM/DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "本月",
            value: () => {
              const start = moment(
                moment()
                  .month(moment().month())
                  .startOf("month")
                  .valueOf()
              ).format("YYYY/MM/DD 00:00:00");
              const end = moment(
                moment()
                  .month(moment().month())
                  .endOf("month")
                  .valueOf()
              ).format("YYYY/MM/DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "上月",
            value: () => {
              const start = moment(
                moment()
                  .month(moment().month() - 1)
                  .startOf("month")
                  .valueOf()
              ).format("YYYY/MM/DD 00:00:00");
              const end = moment(
                moment()
                  .month(moment().month() - 1)
                  .endOf("month")
                  .valueOf()
              ).format("YYYY/MM/DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "本季",
            value: () => {
              const start = moment(
                moment()
                  .quarter(moment().quarter())
                  .startOf("quarter")
                  .valueOf()
              ).format("YYYY/MM/DD 00:00:00");
              const end = moment(
                moment()
                  .quarter(moment().quarter())
                  .endOf("quarter")
                  .valueOf()
              ).format("YYYY/MM/DD 23:59:59");
              return [start, end];
            },
          },
          {
            text: "本年",
            value: () => {
              const start = moment(
                moment()
                  .year(moment().year())
                  .startOf("year")
                  .valueOf()
              ).format("YYYY/MM/DD HH:mm:ss");
              const end = moment(
                moment()
                  .year(moment().year())
                  .endOf("year")
                  .valueOf()
              ).format("YYYY/MM/DD HH:mm:ss");
              return [start, end];
            },
          },
          {
            text: "上年",
            value: () => {
              const start = moment(
                moment()
                  .year(moment().year() - 1)
                  .startOf("year")
                  .valueOf()
              ).format("YYYY/MM/DD HH:mm:ss");
              const end = moment(
                moment()
                  .year(moment().year() - 1)
                  .endOf("year")
                  .valueOf()
              ).format("YYYY/MM/DD HH:mm:ss");
              return [start, end];
            },
          },
        ],
        brandList: [],
        seriesList: [],
        seriesList2: [],
        currentDate: "months",
        chartContent: null,
        keyword: null,
      };
    },
    created() {
      this.getList();
      this.getCustomerData();
    },
    //   mounted() {
    //     this.$nextTick(() => {
    //       this.setChartContent();
    //     });
    //   },
    methods: {
      ...mapActions("purchaseMudule", [
        "saleCategoryCurrent",
        "listSkuCategoryForFirst", //顶级分类请求
        "listSkuCategoryForChildren", //获取子集分类
      ]),
      ...mapActions("homeModule", [
        "userHomeSaleInfo", // 销售信息
        "userHomeSupplyInfo", // 供应商信息
      ]),
      seriesChange(val) {
        if (!val) {
          this.filter2.seriesId = "";
        } else {
          this.filter2.seriesId = val[Object.keys(val).length - 1];
        }
        this.getCustomerData();
      },
      getList() {
        //请求品牌列表
        this.saleCategoryCurrent({
          pageNo: 1,
          pageSize: 9999,
        }).then((res) => {
          let { data, code } = res.data;
          if (code === "0") {
            // data = getLetterSortData(data, "brandName");
            // this.options=new Set(arr)
            let map = new Map();
            for (let item in data) {
              if (data[item]) {
                if (!map.has(item)) {
                  map.set(item, { brandName: data[item], brandId: item });
                }
              }
            }
  
            this.brandList = [...map.values()];
            // data = getLetterSortData(data, "initialCode");
            // data.map((item, index) => {
            //   item.id = "a_" + (index + 1);
            //   item.label = item.letter;
            //   if (item.children) {
            //     item.children.map((son) => {
            //       son.label = son.categoryName;
            //       delete son.children;
            //     });
            //   }
            // });
            //默认选中第一个
            // this.brandList = data;
            // this.filter2.brandId = data[0].children[0].id;
            //根据厂家id获取系列
            this.getSeriesList("filter2", true); //获取当前系列
          }
        });
        // this.getUserHomeOperatingTrend()
      },
      pageChange(page, pageSize) {
        //分页的改变事件
        this.page = page;
        this.pageSize = pageSize;
        this.getCustomerData();
      },
      getSeriesList(key, init) {
        // 获取系列列表
        if (this.filter2.brandId) {
          this.listSkuCategoryForChildren({
            parentId: this[key].brandId,
          }).then((res) => {
            let { data, code } = res.data.data;
            if (code === "0" && data.length) {
              if (init) {
                this.seriesList = handleTreeData(data, "categoryName", "id");
                // this.getCustomerData();
              } else {
                const listKey = key === "filter" ? "seriesList" : "seriesList2";
                this[listKey] = handleTreeData(data, "categoryName", "id");
                // if (key === "filter") {
                //   this.getSupplierData();
                // } else {
                //   this.getCustomerData();
                // }
              }
            }
          });
        }
      },
      //获取第一个id
      getOneId(data, text) {
        if (data.length && data[0].children && data[0].children.length) {
          return this.getOneId(data[0].children);
        } else {
          return data[0].id;
        }
      },
      // 销售信息 切换筛选项
      treeChange2(node, type) {
        this.filter2[type] = node.id;
        if (type === "brandId") {
          // 厂家切换
          this.getSeriesList("filter2");
        } else {
          this.filter2.seriesId = node.id;
          this.getCustomerData();
        }
      },
      changeDate2() {
        if (
          this.filter2.brandId ||
          this.filter2.seriesId ||
          this.filter2.queryDate
        ) {
          this.getCustomerData();
        }
      },
      optionsChange(val) {
        this.seriesList = [];
        this.filter2.seriesId = "";
        this.filter2.seriesIdTemp = "";
        if (!val) {
          this.seriesList = [];
          return;
        }
        this.filter2.brandId = val;
        this.getCustomerData();
        this.getSeriesList("filter2", true); //获取当前系列
      },
      // 拿销售数据
      getCustomerData() {
        let params = {
          brandId: this.filter2.brandId,
          seriesId: this.filter2.seriesId,
          keyword: this.keyword,
          pageNo: this.page,
          pageSize: this.pageSize,
        };
        if (this.filter2.queryDate && this.filter2.queryDate.length) {
          params.startDate = this.filter2.queryDate[0];
          params.endDate = this.filter2.queryDate[1];
        }
        let arr = [];
        this.userHomeSaleInfo(params).then((res) => {
          const { data, code } = res.data;
          if (code === "0") {
            this.total = data.totalCount;
            this.customerData = data.rows;
            // data.rows.map((item) => {
            //   arr.push(item);
            //   // this.options=new Set(arr)
            //   let map = new Map();
            //   for (let item of arr) {
            //     if (!map.has(item.brandId)) {
            //       map.set(item.brandId, item);
            //     }
            //   }
            //   this.brandList = [...map.values()];
            // });
          }
        });
      },
      changeRow(val) {
        // console.log(val);
        let protocol = window.location.protocol;
        let host = window.location.host;
        let href = `${protocol}//${host}/login/sharePage?id=${val.inquiryId}&belongType=0`;
        var a = document.createElement("a");
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.target = "_blank";
        a.click();
      },
      search() {
        this.getCustomerData();
      },
    },
    unmounted() {
      // 销毁实例释放资源
      if (this.chartContent) {
        this.chartContent.destroy();
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  @import "../../style/supplier.less";
  
  .btn {
    cursor: pointer;
    display: inline-block;
    margin: 5px auto;
    border-radius: 5px;
    border: 1px solid #d84146;
    padding: 1px 10px 3px 10px;
    background: #d84146;
    color: #ffffff;
  }
  </style>
  