<template>
	<div class="o">
		<div class="o-title">
			<div class="o-t-left" :key="item.name" style="padding-bottom: 10px;" v-for="item in navs" v-show="item.name == activeName">
				{{ item.text }}：<span> {{ item.desc }}</span>
			</div>
		</div>
		<div class="line"></div>
		<div style="width: 100%;">
			<el-tabs v-model="activeName" type="border-card">

				<el-tab-pane :label="item.text" v-for="item in navs" :name="item.name" :key="item.name">
					<template v-slot:label>
						<span>{{ item.text }}</span>
						<el-badge v-if="item.value - 0" :value='item.value'></el-badge>
					</template>

					<component v-if="activeName == item.name" :is="item.name" @changeCom="changeCom"></component>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import agent from "../components/orderSetup/agent.vue"  //代理
import nonAgency from "../components/orderSetup/nonAgency.vue"  //非代理
import product from "../components/orderSetup/product.vue"  //产品
import manage from "../components/orderSetup/manage.vue"  //管理
import sales from "../components/orderSetup/sales.vue"  //管理
import { mapActions, mapState } from "vuex"
export default {
	data() {
		return {
			activeName: 'agent',
			navs: []
		}
	},


	components: {
		agent,
		nonAgency,
		product,
		manage,
		sales
	},
	methods: {
		...mapActions('mysaleMudule', [
			'userGetHeadCount',
		]),
		getHeadCount() {
			this.userGetHeadCount().then(res => {
				let { code, data } = res.data;
				data.push(
					{ text: '销售大厅', name: 'sales', value: 0 ,desc:"“销售大厅”是一个专为盘雷开发者提供的平台。如果您希望加入盘雷开发者行列，可以通过盘雷小程序“盘雷推广者-盘雷开发者”进行申请和加入。"},
					{ text: '报价管理', name: 'manage', value: 0 ,desc:"“报价管理”模块是您管理报价的中心。在这里，您可以查看所有代理品牌和经营品牌的询价信息，包括自动报价和人工报价。您可以根据需要对这些报价进行进一步的编辑、修改或确认。"}
				)
				this.navs = data
			})
		},
		changeCom(e){
			console.log(e);
			this.activeName = e
		},
	},
	created() {
		this.getHeadCount()
	},
	watch: {

		'$route': {
			handler(v) {

				if (v.path === '/mysale/orderSetup' && v.href.includes('?tab=t2_')) {
					this.activeName = 'product'
				} else {
					this.activeName = 'agent'

				}
			},
			deep: true,
			immediate: true,
		}


	}
}
</script>

<style scoped lang="less">
.o {
	font-size: 14px;
	// width: calc(100% + 30px);
	width: 100%;
	height: calc(100% + 30px);
	position: relative;
	// left: -15px;
	display: flex;
	flex-direction: column;
	.o-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		box-sizing: border-box;
		padding: 0 15px;
		flex-shrink: 0;
		.o-t-left{
			font-weight: bold;
		}
		.o-t-left span {
			color: #D84141;
		}
	}

	.line {
		height: 10px;
		width: 100%;
		flex-shrink: 0;
		background: #F2F5FA;
	}

	.el-tabs {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		/deep/ .el-tabs__content {
			flex: 1;
			padding: 0;
			overflow: hidden;

			.el-tab-pane {
				width: 100%;
				height: 100%;
				
			}
		}
		/deep/ .is-active{
			background: #00a39e !important;
			color: #FFFFFF;
		} 
	}
}
</style>
