<template>
	<el-dialog
	    width="600px"
	  >
		<div class="a">
			<div class="a-title">添加品牌</div>
			<div class="a-content">
				<div class="a-left">
					<div class="a-item">
						<span><i>*</i>选择品牌</span>
						<tree-select
							style="flex: 1;"
							placeholder="请选择品牌"
							v-model="brandId"
							:clearable="false"
							:options="brandList"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							:key="brandId"
							@select="(node) => treeChange(node, 'brandId')"
							:flatten-search-results="true"/>
					</div>
					<!-- <div class="a-item">
						<span><i>*</i>选择代理品牌系列</span>
						<tree-select
							style="flex: 1;"
							placeholder="请选择系列"
							:clearable="false"
							:options="seriesList"
							v-model="seriesId"
							:key="seriesId"
							:disable-branch-nodes="true"
							:searchable="true"
							:defaultExpandLevel="10"
							@select="(node) => treeChange(node, 'seriesId')"
							:flatten-search-results="true"
							/>
					</div> -->
					<!-- <div class="a-item">
						<span><i>*</i>接单折扣</span>
						<el-input v-model="value" placeholder="请输入接单折扣" />
						<p style="margin-left:10px;">%</p>
					</div> -->
				</div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		getLetterSortData, //按字母排序
		handleTreeData, //处理树形数据
	} from "@/common/js/common.js"
	import { mapActions } from "vuex"
	import request from "@/common/js/request.js"
	import api from "@/common/js/api.js"
	import { ElMessage } from 'element-plus'
	import moment from 'moment';
	export default{
		data(){
			return{
				brandId: null, // 厂家id
				seriesId: null, // 系列id
				seriesList:[],  //系列列表
				brandList:[],  //藏家列表
				value:'',  //折扣
			}
		},
		methods:{
			...mapActions('purchaseMudule',
				[
					'listSkuCategoryForFirst', //顶级分类请求
					// 'listSkuCategoryForChildren', //获取子集分类
				]),
			...mapActions('mysaleMudule',[
				'quoteAgentCreate',  //新增代理设置
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			getBrandList(){ //获取品牌列表
				this.listSkuCategoryForFirst({
					pageNo: 1,
					pageSize: 99999
				}).then(res => {
					let {
						data,
						code
					} = res.data.data;
					if (code === "0" && data.length) {
						data = getLetterSortData(data, 'initialCode');
						data.map((item, index) => {
							item.id = 'a_' + (index + 1);
							item.label = item.letter;
							if (item.children) {
								item.children.map(son => {
									son.label = son.categoryName;
									delete son.children;
								})
							}
						})
						//默认选中第一个
						this.brandList = data;
						this.brandId = data[0].children[0].id;
						//根据厂家id获取系列
						// this.getSeriesList(); //获取当前系列
					}
				})
			},
			getSeriesList(){ //获取系列列表
				this.listSkuCategoryForChildren({
					parentId: this.brandId,
				}).then(res => {
					let {
						data,
						code
					} = res.data.data;
					if (code === "0" && data.length) {
						this.seriesList = handleTreeData(data, 'categoryName');
						// this.seriesId = this.getOneId(this.seriesList);
					}
				})
			},
			//获取第一个id
			getOneId(data, text) {
				if (data.length && data[0].children && data[0].children.length) {
					return this.getOneId(data[0].children);
				} else {
					return data[0].id;
				}
			},
			treeChange(node,type){ //树形选择的事件
				this[type] = node.id;
				if(type === "brandId"){ //厂家切换
					this.getSeriesList();
				}
			},
			confirm(){ //确认的点击事件
				var text = '';
				if(!this.brandId){
					text = "请选择代理品牌";
				}
				// else if(!this.seriesId){
				// 	text = "请选择代理品牌系列";
				// }
				// else if(!this.value){
				// 	text = "请输入接单折扣";
				// }
				if(text){
					ElMessage({
						type:'warning',
						message:text,
					})
				}else{
					// console.log(this.brandId);
					this.quoteAgentCreate({
						brandId:this.brandId,
						// belongId:this.seriesId,
						// discount:this.value,
						type:'DIS_AGENT',
					}).then(res => {
						// console.log(res);
						let {code,data} = res.data;
						if(code === '0'){
							ElMessage({
								type:'success',
								message:'添加成功'
							})
							//关闭弹框
							// location.reload();
							this.close();
							this.$emit('success');
						}
					})
				}
			}
		},
		created(){
			this.getBrandList();
		},
	}
</script>

<style scoped lang="less">
	.a{
		font-size: 14px;
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding: 0 20px 20px 0;
		}
		.a-footer{
			display: flex;
			justify-content: center;
		}
		.a-content{
			display: flex;
			align-items: stretch;
		}
		.a-left{
			flex: 5;
			.a-item{
				display: flex;
				align-items: center;
				padding-bottom: 60px;
				span{
					width: 140px;
					margin-right: 10px;
					text-align: right;
					i{
						margin-right: 4px;
						line-height: 20px;
						color: #FF1A1D;
					}
				}
			}
		}
		.a-right{
			flex: 3;
			box-sizing: border-box;
			padding-left: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.up-img{
				width: 180px;
				height: 180px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #FBFDFF;
				border-radius: 10px;
				border: 1px solid #eeeeee;
				overflow: hidden;
				p{
					font-size: 40px;
					color: #999999;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.a-r-title{
				text-align: center;
				padding-bottom: 20px;
				i{
					color: #FF1A1D;
					margin-right: 4px;
				}
			}
		}
	}

</style>
