<template>
  <div style="padding: 0 15px;">
    <el-tabs v-model="formData.tab" @tab-click="search" type="card">
      <el-tab-pane :label="item.name" :name="item.id" v-for="item in tabText" :key="item.id"></el-tab-pane>
    </el-tabs>
  </div>

  <div class="p">
    <div class="p-title">
      <div style="display:flex;align-content: flex-start;">
        <div style="marginRight:20px">
          <el-select v-model="formData.brandId" placeholder="请选择品牌" @change="optionsChange">
            <el-option label="所有品牌" value=""></el-option>
            <el-option v-for="item in options" :key="item.brandId" :label="item.brandName" :value="item.brandId">
            </el-option>
          </el-select>
        </div>
        <div style="marginRight:20px;marginTop:-5px;width: 230px;">
          <el-input v-model.trim="formData.wd" placeholder="请输入型号、订货号" @change="changeBarCode" clearable></el-input>
        </div>
        <div style="marginRight:20px">
          <el-date-picker style="width:400px;margin-right:20px" class="date-picker-box" v-model="queryDate"
            type="daterange" :clearable="true" unlink-panels append-to-body="true" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="search" format="YYYY/MM/DD"
            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
        </div>

        <div style="marginRight:20px;">
          <el-button type="primary" size="mini" @click="search">搜索</el-button>
        </div>
        <div style="line-height: 30px;color:#f56c6c;font-weight: bold;">当前共{{ Math.ceil(total / pageSize) }}页，
          共{{ total }}份报价</div>
      </div>
    </div>

    <el-radio-group v-model="formData.timeWd" @change="search" style="margin-bottom: 15px">
      <el-radio :label="item.id" size="large" border v-for="item in shortcuts" :key="item.id">{{ item.text }}</el-radio>
    </el-radio-group>

    <lida-table :cell-style="{
        background: '#ecf5ff'
      }" rowKey="quoteId" :defaultExpandAll="true" :data="list" border :total="total" :page="page" :pageSize="pageSize"
      @change="pageChange" ref="table" @selection-change="selectionChange">



      <lida-table-column type="expand" label="展开">
        <template #default="props">
          <el-table :data="props.row.skuVos" border style="margin: -12px 0;"
            :header-cell-style="{ background: '#f2f5fa', color: '#909399' }">
            <lida-table-column label="商品品牌" prop="brandName" />
            <lida-table-column label="报价数量" prop="qty" />
            <lida-table-column label="产品信息">
              <template #default="scope">
                <div class="g-info-right"
                  style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                  <div class="g-info-row" style="text-align: center;">参数:{{ scope.row.barCode }}</div>
                  <div class="g-info-row" style="text-align: center;">订货号:{{ scope.row.articleNo }}</div>
                  <div class="g-info-row" style="text-align: center;">商品名称:{{ scope.row.seriesName || '暂未设置' }}</div>
                </div>
              </template>
            </lida-table-column>
            <lida-table-column label="报价价格" prop="quotePrice" />

            <lida-table-column label="小计"  :min-width="140" align="center">
              <template #default="scope">
                <div class="t-profit">
                  <span>{{ ((scope.row.quotePrice * scope.row.qty) || 0).toFixed(2) }}</span>
                </div>
              </template>
            </lida-table-column>

            <lida-table-column label=" ">
              <template #default="scope">
                <div>
                  <el-button type="warning" v-if="scope.row.isQuote == 1" size="mini"
                    @click="commands(props.row.quoteId, scope.row)">修改报价</el-button>
                  <el-button type="danger" v-if="scope.row.isQuote == 2" size="mini"
                    @click="commands(props.row.quoteId, scope.row)">新增报价</el-button>


                </div>
              </template>
            </lida-table-column>
          </el-table>
        </template>
      </lida-table-column>


      <lida-table-column label="询价时间" prop="createTime" />
      <lida-table-column label="询价截止日期" prop="shelfLife" />
      <lida-table-column label="询价来源" prop="inquirySource" />
      <lida-table-column label="报价来源" prop="quoteSource" />

      
      <lida-table-column label="询价人" prop="inquiryUserName" />
      <lida-table-column label="询价公司" prop="inquiryEnterpriseName" />
      <lida-table-column label="询价电话" prop="inquiryPhone" />


      <lida-table-column label="">
        <template #default="scope">

          <div class="flex">
            <el-button type="success" size="mini" @click="historyQuote(scope.row)">报价历史</el-button>
            <el-button type="danger" v-if="scope.row.documentState != 'STOP'" size="mini"
              @click="stopQuote(scope.row)">放弃报价</el-button>
            <el-tag type="danger" v-if="scope.row.documentState == 'STOP'">已放弃</el-tag>
          </div>
        </template>
      </lida-table-column>
    </lida-table>

    <el-dialog v-model="historyQuoteShow" width="60%">
      <div class="iu-title">报价历史
      </div>

      <div class="iu-title">
        <lida-table :data="historyList" :pagination="false">
          <lida-table-column label="商品品牌" prop="brandName" />
          <lida-table-column label="商品名称" prop="skuName" />
          <lida-table-column label="报价时间" prop="quoteTime" />
          <lida-table-column label="报价价格" prop="price" />
        </lida-table>

        <div style="display: flex;justify-content: center;margin-top: 20px;"> <el-button type="success" size="mini"
            @click="exportInquiry">导出报价历史</el-button></div>
      </div>

    </el-dialog>
    <editQouteOffer :info="activeInfo" v-model="editShow" @success="search" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import { ElMessage } from "element-plus";
import editQouteOffer from "../dialog/editQouteOffer.vue"; //编辑弹框
import moment from "moment";

import exportExcel from "@/common/js/exportExcel.js"; //导出数据
moment.locale('zh-cn')
export default {
  data() {
    return {
      list: [], //商品列表
      editShow: false, //编辑弹框的隐藏显示
      page: 1, //当前页数
      pageSize: 50, //每页显示的数量
      total: 0, //数据总数
      formData: {
        wd: "",
        brandId: "",
        tab: 1,
        timeWd: 3
      },
      queryDate: [
        // moment()
        //     .subtract(1, "month")
        //     .format("YYYY-MM-DD 00:00:00"),
        // moment().format("YYYY-MM-DD 23:59:59"),
      ],
      options: [],
      activeInfo: {}, //当前选中的数据
      shortcuts: [
        {
          text: '今日',
          id: 1,
        },
        {
          text: '昨日',
          id: 2,
        },
        {
          text: '最近3天',
          id: 3,
        },
        {
          text: '最近一周',
          id: 4,
        },
        {
          text: '本月',
          id: 5,
        },
        {
          text: '上月',
          id: 6,
        },
        {
          text: '本季度',
          id: 7,
        },
        {
          text: '本年',
          id: 8,
        }
      ],
      quoteSwitch: {},
      tabText: [
        { name: '待处理', id: 1 },
        { name: '已处理', id: 2 },

        { name: '已过期', id: 8 },

        // { name: '已放弃', id: 3 },
        // { name: '自动报价', id: 4 },
        // { name: '人工报价', id: 5 },
        // { name: '主动报价', id: 6 },
        // { name: '链接报价', id: 7 },
        // { name: '全平台询价', id: 9 },
        // { name: '客户询价', id: 10 },
      ],
      historyQuoteShow: false,
      historyList: []
    };
  },
  components: {
    editQouteOffer
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "quoteBrandList",
      "pageSellerByQuote",
      "quoteInfo",
      "quoteWaiveQuote",
      "quoteHistoryQuote"
    ]),
    ...mapActions("purchaseMudule", [

    ]),
    getBrand() {
      //请求品牌列表
      this.quoteBrandList().then((res) => {
        let { data, code } = res.data
        if (code === "0" && data.length) {
          this.options = data
        }
      });
    },

    exportInquiry() {
      var arr = [];
      var data = JSON.parse(JSON.stringify(this.historyList));
      data.map((item) => {
        arr.push({
          商品品牌: item.brandName,
          商品名称: item.skuName,
          报价时间: item.quoteTime,
          报价价格: item.price,
        });
      });
      exportExcel(arr, "报价历史");
    },
    optionsChange(val) {
      this.formData.brandId = val;
      this.search();
    },
    changeBarCode(val) {
      this.formData.wd = val
      this.search();
    },
    stopQuote(e) {
      this.quoteWaiveQuote({
        quoteId: e.quoteId
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "报价已放弃"
          });
          this.search();
        }
      });
    },
    search() {
      // console.log(this.queryDate);
      this.pageSellerByQuote({
        pageSize: this.pageSize,
        pageNo: this.page,
        ...this.formData,
        startDate: this.queryDate[0],
        endDate: this.queryDate[1],
        keyword: this.input,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          console.log(data.rows);
          data.rows.map((item) => {
            return offerProfitCompute(item);
          });
          this.list = data.rows;
          this.total = data.totalCount;
        }
      });
    },
    historyQuote(e) {
      this.historyQuoteShow = true
      this.getHistoryQuoteShow(e.inquiryId)
    },
    getHistoryQuoteShow(id) {
      this.quoteHistoryQuote({
        inquiryId: id,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.historyList = data
        }
      });
    },
    pageChange(page, pageSize) {
      //分页的改变事件
      this.page = page;
      this.pageSize = pageSize;
      this.search();
    },
    getOfferInfo(id, skuData) { //获取报价信息
      this.quoteInfo({
        quoteId: id
      }).then(res => {
        let {
          code,
          data
        } = res.data;
        if (code === '0') {
          var filterItem = data.itemRtnVOList.filter(data => {
            console.log(data.skuId, skuData,111)
            return data.skuId == skuData.skuId
          })
          filterItem = filterItem.length ? filterItem[0] : skuData;
          this.activeInfo = {
            isQuote: skuData.isQuote,
            inquiryId: data.quoteRtnVO.inquiryId,
            buyerEnterpriseId: data.quoteRtnVO.buyerEnterpriseId,
            buyerId: data.quoteRtnVO.buyerId,
            profitSite: 0,
            purchaseDis: 0,
            purchasePrice: 0,
            guidePrice: 0,
            guideSite: 0,
            salesDis: 0,
            salesPrice: 0,
            channel: null,
            ...filterItem,
          }
        }
      })
    },

    commands(id, skuData) {
      this.editShow = true;
      this.getOfferInfo(id, skuData)
    },

  },
  created() {
    this.getBrand()
    this.search();
  },
};
</script>

<style scoped lang="less">
.p {
  box-sizing: border-box;
  padding: 0 15px 15px;
}

.p-title {
  padding: 0 0 10px;

  .p-t-left {
    margin-bottom: 20px;
    color: red;
  }
}

.g-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .g-icon {
    img {
      width: 60px;
      height: 60px;
    }
  }

  .g-right {
    flex: 1;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .g-r-title {
      font-size: 14px;
      display: flex;

      .label {
        max-width: 100px;
        background: @dh-color;
        border-radius: 0 10px 10px 10px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        padding: 0 8px;
        margin-left: 10px;
        height: 24px;
        box-sizing: border-box;
      }
    }

    .g-r-data {
      color: #666666;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin: 0 10px;
      }
    }
  }
}

.t-profit {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .t-profit-i {
    display: flex;
    padding-bottom: 5px;

    span {
      width: 100px;
      color: #999999;
    }
  }
}

.iu-title {
  font-weight: bold;
  padding: 20px;
}
</style>
