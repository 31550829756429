<template>
  <el-dropdown>
    <span class="el-dropdown-link iconfont icon-xianxingtubiaozhizuomoban-25"></span>
    <template #dropdown>
      <el-dropdown-menu>
		<div v-for="(item, index) in data" :key="index">
			<el-dropdown-item  @click="handleCommand(item.name)" :command="item.name" v-if="getShow(item)">
			    <span v-if="item.icon" :class="['iconfont', itme.icon]"></span>
			    {{item.name}}
			</el-dropdown-item>
		</div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
export default {
    props: {
        data: Array,
        row: Object,
        index: Number
    },
    emits: ['command'],
    methods: {
        handleCommand(val) {
            let obj = {dropdownName: val, row: this.row, index: this.index}
            this.$emit('command', obj)
        },
		getShow(item){
			var row = this.row;
			return item.show ? eval(item.show) : true;
		}
    }

}
</script>
<style lang="less" scoped>
.el-dropdown-link{
    cursor: pointer;
}
</style>

