<template>
  <el-dialog width="600px" top="10vh" :before-close="close">
    <div class="e">
      <div class="e-title">
        利润编辑
        <span v-if="data.productName"
          >( {{ data.productName + "|" + data.barCode }} )</span
        >
      </div>
      <div class="e-content">
        <div class="e-row">
          <span>生产日期:</span>
          <el-date-picker
            v-model="data.manufactureDate"
            type="date"
            placeholder="请选择生产日期"
          >
          </el-date-picker>
        </div>
        <div class="e-row">
          <span>渠道:</span>
          <el-select v-model="data.channel" placeholder="请选择渠道">
            <el-option
              v-for="item in channel"
              :key="item.title"
              :label="item.title"
              :value="item.title"
            ></el-option>
          </el-select>
        </div>
        <div class="e-row">
          <span>面价:</span>
          <el-input
            v-model="data.guidePrice"
            @input="inputLv(data)"
            placeholder="请输入面价"
          />
        </div>
        <div class="e-row">
          <span>采购折扣 ( % ):</span>
          <el-input
            v-model="data.purchaseDis"
            @input="inputLv(data, 'purchaseDis')"
            placeholder="请输入面价点数"
          />
        </div>
        <div class="e-row">
          <span>采购单价:</span>
          <el-input
            v-model="data.purchasePrice"
            @input="inputLv(data, 'purchasePrice')"
            placeholder="请输入采购单价"
          />
        </div>
        <div class="e-row">
          <span>面价点数 ( % ):</span>
          <el-input
            v-model="data.guideSite"
            @input="inputLv(data, 'guideSite', 'profitSite')"
            placeholder="请输入面价点数"
          />
        </div>
        <div class="e-row">
          <span>利润点数 ( % ):</span>
          <el-input
            v-model="data.profitSite"
            @input="inputLv(data, 'profitSite', 'guideSite')"
            placeholder="请输入利润点数"
          />
        </div>
        <div class="e-row">
          <span>销售折扣 ( % ):</span>
          <el-input
            v-model="data.salesDis"
            @input="inputLv(data, 'salesDis')"
            placeholder="请输入面价点数"
          />
        </div>
        <div class="e-row">
          <span>销售价格:</span>
          <el-input
            v-model="data.salesPrice"
            @input="inputLv(data, 'salesPrice')"
            placeholder="请输入面价点数"
          />
        </div>
        <div class="e-row">
          <span>数量:</span>
          <div style="flex: 1;">
            <el-input-number
              @change="inputLv(data)"
              v-model="data.qty"
              :min="0"
            />
          </div>
        </div>
        <div class="e-row" v-if="hand">
          <span>地区:</span>
          <div style="flex: 1;">
            <el-cascader
              v-model="data.shipArea"
              :options="areaInfo"
              size="small"
              :props="{ expandTrigger: 'hover' }"
              style="width: 200px;"
              @change="zkBlur(data)"
            ></el-cascader>
          </div>
        </div>
        <div class="e-info">
          <div class="e-info-row">
            <span>实际利润:</span>
            <p>{{ data.profitC }}</p>
          </div>
          <div class="e-info-row">
            <span>总利润:</span>
            <p>{{ data.totalProfitC }}</p>
          </div>
        </div>
      </div>
      <div class="e-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close"
          >取消</el-button
        >
        <el-button
          style="margin-left: 20px;"
          type="primary"
          size="mini"
          @click="confirm"
          >确认</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import offerProfitCompute from "@/common/js/offerProfitCompute.js";
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";
import moment from "moment";
import { handleTreeData } from "@/common/js/common.js";
import area from "@/common/json/area.json";
export default {
  data() {
    return {
      data: {},
      areaInfo: handleTreeData(area, "name", "name"), //地区信息
      hand: true,
    };
  },
  props: ["info"],
  computed: {
    ...mapState("mysaleMudule", ["channel"]),
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "quoteSetSkuUpdate", //修改报价设置商品
      "quoteSetSkuUpdateBatch", //批量修改报价设置商品
    ]),
    close() {
      //关闭弹框
      this.$emit("update:modelValue", false);
    },
    inputLv(row, type, linkage) {
      //监听面价点数和利润点数输入的事件
      row.numType = "qty";
      this.data = offerProfitCompute(row, type, linkage);
    },
    zkBlur(data, val) {
      this.hand = false;
      this.$nextTick((_) => {
        this.hand = true;
      });
    },
     //确认的点击事件
    confirm() {
       this.data.shipArea = this.data.shipArea.map((v) => v).join();
      var data = JSON.parse(JSON.stringify(this.data));
        this.quoteSetSkuUpdate(data).then((res) => {
          let { data, code } = res.data;
          if ((code === "0" && !data) || (data && data.code === "0")) {
            ElMessage({
              type: "success",
              message: "编辑成功",
            });
            this.$emit("success"); //添加成功回调
            this.close();
          } else {
            ElMessage({
              type: "warning",
              message: data.mag,
            });
          }
        });
    },
  },
  watch: {
    info(v) {
      if (v instanceof Array === false) {
        console.log(v)
        v.channel = v.channel || "现货";
        v.manufactureDate = v.manufactureDate || moment().format("YYYY-MM-DD");
        if(v.shipArea&&v.shipArea.length) {
          v.shipArea=v.shipArea.split(',')
        }
        this.data = v;

      }
    },
  },
};
</script>

<style scoped lang="less">
.e {
  padding: 20px;
  .e-title {
    font-weight: bold;
    padding-bottom: 10px;
    span {
      color: @dh-color;
      margin: 0 5px;
    }
  }
  .e-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .e-content {
    padding: 0 40px;
    .e-row {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      span {
        min-width: 110px;
        margin-right: 10px;
        text-align: right;
        white-space: nowrap;
      }
    }
    .e-info {
      display: flex;
      flex-wrap: wrap;
      .e-info-row {
        width: 50%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        span {
          width: 100px;
          margin-right: 20px;
          text-align: right;
          color: #999999;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
